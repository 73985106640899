import React, { Component } from 'react';
import $ from 'jquery';

class Login extends Component {
  componentDidMount() {
    $('#loader').css({
        display: 'none'
    })    
}

  render() {
    return (
      <section class="home_banner_area">
        <div class="at-haslayout at-innerbannerholder">
          <div class="container">
            <div class="row justify-content-md-center">
              <div class="col-12 col-md-12">
                <div class="at-innerbannercontent">
                  <div class="at-title"><h2>Page Lost In the Sea</h2></div>
                  <ol class="at-breadcrumb">
                    {/* <li><a href="index-2.html">Main</a></li> */}
                    <li>404 Error</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <main id="at-main" class="at-main at-haslayout">
          <div class="at-haslayout at-main-section">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-sm-12 col-md-12 push-md-0 col-lg-10 push-lg-1 col-xl-8 push-xl-2">
                  <div class="at-error">
                    <figure class="at-error-img"><img src="/kanadnetworks/img/404.gif" alt="404 img" /></figure>
                    <div class="at-title">
                      <h3>Ooops! This Page is No Longer Available</h3>
                    </div>
                    <div class="at-description">
                      <p></p>
                    </div>
                    <div class="at-btnarea">
                      <a href="/" class="at-btn">Back To Homepage</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
    );
  }
}

export default Login;
