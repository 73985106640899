import React from 'react';

class Timer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			seconds: this.props.time, // in seconds
	    	time:  '00:00:00',
	    	t: ''
		};
	}
	componentDidUpdate(prevProps, prevState) {
	  if (prevProps.time !== this.props.time) {
	    this.setState({
			seconds: this.props.time
		})
		clearInterval(this.state.t)
		this.timer()
	  }
	}

    setTime(sec) {
    	var date = new Date(null);
		date.setSeconds(sec); // specify value for SECONDS here
		return date.toISOString().substr(11, 8);
    }

    timer() {
    	this.setState({
			t: setInterval(() => {
				this.setState({
					seconds: this.state.seconds - 1,
					time: this.setTime(this.state.seconds)
				})
				if(this.state.seconds < 0){
					clearInterval(this.state.t)
					this.setState({
						time: '00:00:00'
					})
				}
			}, 1000)
    	})
    }    

	render() {
		return (
			<label style={{display:'inline', marginRight:'10px'}} className="timer">{this.state.time}</label>
		);
	}
}

export default Timer;