import React, { Component } from 'react'
import { Dialog, Button, Snackbar } from '@material-ui/core';
import Cookies from 'universal-cookie';
import Facebook from './Facebook';
import Google from './Google';
import API from '../../api';
import { getUrl, postUrl } from '../../constants/url';
import validator from 'validator';
import ForgotPass from './ForgotPass';
import { cookies } from '../../constants/strings';
import 'firebase/auth'
import Timer from '../../components/Timer';
import Loader from '../Loader';

export default class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            formErrors: {
                email: '',
                pass: '',
            },
            emailAddess: '',
            password: '',
            IsDialogOpen: this.props.IsLoginOpen ? this.props.IsLoginOpen : false,
            passText: 'Password',
            loginByOTP: false,
            otpDisplay: 'block',
            timer: '',
            openSnakbar: false,
            message: '',
            dialogWidth: window.innerWidth > 770 ? '35%' : (window.innerWidth > 760 ? '60%' : '109%'),
            dialogLeft: window.innerWidth > 770 ? '30%' : (window.innerWidth > 760 ? '20%' : '-5%'),

            loginBtn: 'Login',
            loginBtnOTP: 'Login by OTP'
        }
        this.cookies = new Cookies();
        this.SuccessResponse = this.SuccessResponse.bind(this);
        this.msgHide = this.msgHide.bind(this);
        this.userChange = this.userChange.bind(this);
        this.developmentClick = this.developmentClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.IsLoginOpen !== this.props.IsLoginOpen) {
            this.setState({ IsDialogOpen: this.props.IsLoginOpen })
        }
    }

    msgHide() {
        this.setState({
            openSnakbar: false,
            message: '',
        })
    }

    verify() {
        const errors = this.state.formErrors;
        let ver = true;

        if (!validator.isEmail(this.state.emailAddess)) {
            errors.email = 'Invalid email';
            ver = false;
        }

        if (this.state.password === '') {
            if (this.state.loginByOTP === true)
                errors.pass = 'OTP is mandatory';
            else
                errors.pass = 'Password is mandatory';
            ver = false;
        }
        this.setState({ formErrors: errors });
        return ver;
    }

    onLoginBtn() {
        if (this.verify()) {
            const data = {
                email: this.state.emailAddess,
                password: this.state.password,
                loginByOTP: this.state.loginByOTP
            }
            this.setState({ loginBtn: <Loader /> })
            API.post(postUrl.login, data).then(res => {
                if (res.data.status === 200) {
                    this.setState({
                        emailAddess: '',
                        password: '', IsDialogOpen: false,
                        openSnakbar: true,
                        message: 'Sign In Successful.',
                        loginBtn: 'Login'
                    })

                    var user = res.data.data.user;
                    this.cookies.set(cookies.user, user[0], { path: '/' });
                    this.props.onSuccess();
                } else {
                    this.setState({ openSnakbar: true, message: res.data.error, loginBtn: 'Login' })
                }
            })
        }
    }

    onLoginByOTP() {
        const formErrors = this.state.formErrors;

        if (this.state.emailAddess.length == 0) {
            formErrors.email = 'Email is mandatory';
            this.setState({ formErrors })
            return;
        }
        const data = {
            email: this.state.emailAddess
        }
        this.setState({ loginBtnOTP: <Loader /> })
        API.post(postUrl.sendOTP, data).then(res => {
            if (res.data.status === 200) {
                this.setState({
                    formErrors: { email: '', pass: this.state.formErrors.pass },
                    passText: 'Enter OTP here',
                    otpDisplay: 'none',
                    loginByOTP: true,
                    loginBtnOTP: 'Login by OTP'
                })
                this.setState({ openSnakbar: true, message: res.data.error })
            } else {
                this.setState({ openSnakbar: true, message: res.data.error, loginBtnOTP: 'Login by OTP' })
            }
        })
    }

    SuccessResponse(response, type) {
        var userData = {
            name: null,
            password: '',
            email: type,
            country: 1,
            login_type: 1
        }
        if (type == 3) { // Google
            userData.name = response.profileObj.name
            userData.email = response.profileObj.email
            userData.login_type = 3;
        } else if (type == 2) {  // facebook
            userData.name = response.name
            userData.email = response.email
            userData.login_type = 2;
        }
        API.post(postUrl.register, userData).then(response => {
            if (response.data.status === 200) {
                var user = response.data.data.user;
                this.cookies.set(cookies.user, user[0], { path: '/' });

                this.setState({ IsDialogOpen: false });
                this.props.onSuccess();
            } else {
                this.setState({ openSnakbar: true, message: response.data.error })
            }
        })
    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            this.onLoginBtn();
        }
    }

    userChange(event) {
        const { target: { value } } = event;
        const formErrors = this.state.formErrors;
        formErrors.email = '';
        this.setState({ emailAddess: value, formErrors })
        // if (validator.isEmail(value)) {
        //     this.setState({
        //         formErrors: { email: '', pass: this.state.formErrors.pass },
        //         passText: 'Password'
        //     })
        // }
        // else if (value.length > 0) {
        //     this.setState({
        //         formErrors: { email: 'Invalid Email or Number', pass: this.state.formErrors.pass },
        //         passText: 'Password'
        //     })
        // }
        // else {
        //     this.setState({
        //         formErrors: { email: '', pass: this.state.formErrors.pass },
        //         passText: 'Password'
        //     })
        // }
    }

    passChange(event) {
        const { target: { value } } = event;
        const formErrors = this.state.formErrors;
        formErrors.pass = '';
        this.setState({
            password: value, formErrors
        })
    }

    developmentClick() {
        this.setState({ message: 'Due to unavailablity of domain name, this functionality is disabled', openSnakbar: true })
    }

    render() {
        return (
            <div>
                <Dialog open={this.state.IsDialogOpen} style={{ width: this.state.dialogWidth, left: this.state.dialogLeft }} onBackdropClick={() => {
                    this.setState({ IsDialogOpen: false, emailAddess: '', password: '', formErrors: { email: '', pass: '' } })
                    this.props.onLoginClose()
                }}>
                    <div className="at-modalcontent modal-content" >
                        <div className="at-popuptitle">
                            <h4>Login</h4>
                            <a className="at-closebtn close" onClick={() => {
                                this.setState({ IsDialogOpen: false, emailAddess: '', password: '', formErrors: { email: '', pass: '' } })
                                this.props.onLoginClose()
                            }}><i className="lnr lnr-cross" data-dismiss="modal"></i></a>
                        </div>
                        <div className="modal-body" style={{ padding: 15, paddingTop: 0 }}>
                            <div style={{ marginTop: '5px' }}>
                                <input type="email" className="form-control" id="outlined-uname" name="outlined-uname" value={this.state.emailAddess} onChange={this.userChange} placeholder="Email*" />
                                <p style={{ fontSize: '12px' }}>{this.state.formErrors.email}</p>
                            </div>
                            <div>
                                <input type="password" className="form-control" id="outlined-password" name="outlined-password"
                                    value={this.state.password} onKeyPress={(e) => this.onKeyPress(e)}
                                    onChange={(e) => this.passChange(e)}
                                    placeholder={this.state.passText} style={{ fontSize: '15px' }} />
                                <p style={{ fontSize: '12px' }}>{this.state.formErrors.pass}</p>
                            </div>
                            {/* <input id="sign-in-button" type="button" onClick={() => this.onLoginBtn()} /> */}
                            <div className="form-group at-btnarea">
                                <button id="login-button" className="btn submit_btn" onClick={() => this.onLoginBtn()}>{this.state.loginBtn}</button>
                                {
                                    this.state.loginByOTP === true ?
                                        <span style={{ float: 'right', marginTop: '10px' }}><em>Not received?</em><a style={{ cursor: 'pointer', color: 'blue', marginRight: '8px' }} onClick={() => this.onLoginByOTP()}> Resend OTP</a></span>
                                        : <button id="login-button" style={{ float: 'right', display: this.state.otpDisplay }}
                                            className="btn submit_btn" onClick={() => this.onLoginByOTP()}>{this.state.loginBtnOTP}</button>
                                }
                            </div>
                            <span className="at-optionsbar" style={{ marginTop: 5, marginBottom: 10 }}><em>or</em></span>
                            <div className="at-loginicon">
                                <ul>
                                    <li><Facebook onFacebookSuccessResponse={this.SuccessResponse} /></li>
                                    <li><Google onGoogleSuccessResponse={this.SuccessResponse} /></li>
                                </ul>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="at-popup-footerterms">
                                <span>By signing in  you agree to these <a href="javascript:void(0);"> Terms &amp; Conditions</a> &amp; consent to<a href="javascript:void(0);"> Cookie Policy &amp; Privacy Policy.</a></span>
                            </div>
                            <div className="at-loginfooterinfo">
                                <a onClick={() => this.props.onSignupNow()} style={{ cursor: 'pointer' }}><em>Not a member?</em> Signup Now</a>
                                <a className="at-forgot-password" style={{ cursor: 'pointer' }} onClick={() => this.props.onForgotPassOpen()} >Forgot password?</a>
                            </div>
                        </div>
                    </div>
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        open={this.state.openSnakbar}
                        onClose={this.msgHide}
                        autoHideDuration={5000}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{this.state.message}</span>}
                    />
                </Dialog>
            </div>
        )
    }
}
