
import React from 'react'
import $ from 'jquery';
import Cookies from 'universal-cookie';
import { cookies } from '../../constants/strings';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import UploadedFiles from './uploadedfiles';
import ReceivedFiles from './receivedfiles';
import { FullScreen } from "react-full-screen";

export default class ManageFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.user = null
        this.cookies = new Cookies();
    }
    componentDidMount() {        
        const user = this.cookies.get(cookies.user);
        if (user) {
            this.user = user;
        }
    }

    render() {
        return (
            <section class="home_banner_area">
                <Tabs style={{ marginTop: '5px', paddingRight: '20px', paddingLeft: '20px' }}>
                    <TabList>
                        <Tab>Uploaded files</Tab>
                        <Tab>Received files</Tab>
                    </TabList>

                    <TabPanel>
                        <UploadedFiles></UploadedFiles>
                    </TabPanel>
                    <TabPanel>
                        <ReceivedFiles></ReceivedFiles>
                    </TabPanel>
                </Tabs>
            </section>
        )
    }
};