import DataTable, { memoize } from 'react-data-table-component';
import React from 'react'
import $ from 'jquery';
import API from '../../api';
import Snackbar from '@material-ui/core/Snackbar';
import { postUrl, pages, getUrl } from '../../constants/url';
import { Link } from 'react-router';
import Cookies from 'universal-cookie';
import { cookies } from '../../constants/strings';
import Forward from './forward';

const customStyles = {
    rows: {
        style: {
            minHeight: '40px', // override the row height
            maxHeight: '40px',
            striped: true
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            fontWeight: 'bold',
            fontSize: '15px'
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            fontSize: '14px',
            center: true
        },
    },
};

export default class UploadedFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errMessage: '',
            open: false,
            data: [],
            fileFound: false,
            isOpenForward: false,
            selRow: ''
        }
        this.msgHide = this.msgHide.bind(this);
        this.user = null
        this.cookies = new Cookies();
        this.onForwardClose = this.onForwardClose.bind(this);

        this.columns = [
            {
                name: 'Name',
                selector: 'file_name',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                cell: row => <div title={row.file_name} style={{ fontWeight: 'bold' }}>{row.file_name.length > 20 ? `${row.file_name.slice(0, 20)}...` : row.file_name}</div>,
            },
            {
                name: 'Description',
                selector: 'description',
                wrap: true,
                sortable: true,
                format: row => <div title={row.description}> {row.description.length > 25 ? `${row.description.slice(0, 25)}...` : row.description} </div>,
            },
            {
                name: 'Size',
                selector: 'filesize',
                sortable: true,
                grow: 0,
                cell: row => <div style={{ width: '150px' }}>{row.filesize} (MB)</div>,
            },
            {
                name: 'Download',
                selector: 'downloadcount',
                sortable: true,
                grow: 0,
                cell: row => <div style={{ width: '150px' }}>{row.downloadcount}</div>,
            },
            {
                name: 'Upload',
                selector: 'upload_date',
                sortable: true,
            },
            {
                name: 'Expire',
                selector: 'expire_date',
                sortable: true,
            },
            {
                grow: 0,
                ignoreRowClick: true,
                cell: row => <div style={{ minWidth: '120px' }}>
                    <button onClick={() => this.onForward(row)} disabled={row.expire < 0} className="btn btn-primary" style={{ width: '40px', marginLeft: '1px', padding: '3px 10px' }}
                        title='Forward'><i className='fa fa-arrow-right'></i></button>
                    <Link to={row.expire < 0 ? '' : '/public/files/' + row.filecode + "/" + row.file_name} style={{ width: '40px', marginLeft: '5px', padding: '3px 10px', cursor: (row.expire < 0 ? 'inherit' : 'pointer') }}
                        className="btn btn-primary" title="Download" target="_blank"
                        onClick={(e) => { row.expire < 0 ? e.preventDefault() : this.download(row.id, row.filesize) }} download><i style={{ color: 'white' }} className='fa fa-download'></i>
                    </Link></div>,
            },
            {
                grow: 0,
                ignoreRowClick: true,
                cell: row => <button onClick={() => this.delete(row.id, row.filecode, row.file_name)} disabled={row.expire < 0} className="btn btn-danger" style={{ width: '40px', marginLeft: '-7px', padding: '3px 10px' }}
                    title='Delete'><i className='fa fa-trash'></i></button>,
            },
        ];
    }
    componentWillMount() {
        const user = this.cookies.get(cookies.user);
        if (user) {
            this.user = user;
            this.getFileDetails();            
        }     
        $('#loader').css({
            display: 'none'
        })  
    }

    getFileDetails() {
        API.post(getUrl.getUploadFilesByUser, { user_id: this.user.User_Id }).then(res => {
            if (res.data.data.fileDetail.length > 0) {
                this.setState({ data: res.data.data.fileDetail, fileFound: true })
            }
            else {
                this.setState({
                    fileFound: true
                })
            }
        }, error => {
            console.error(error);

            this.setState({
                open: true,
                errMessage: 'Some error occured'
            })
        });
    }

    msgHide() {
        this.setState({ errMessage: '', open: false })
    }

    download(file_id, filesize) {
        API.post(postUrl.downloadCount, {
            file_id: file_id,
            file_size: filesize,
            user_code: this.user.UserCode
        }).then(res => {
            if (res.data.status === 200) {

            }
        }, error => {
            console.error(error);
            this.setState({
                open: true,
                errMessage: 'Some error occured'
            })
        });
    }

    delete(file_id, file_code, file_name) {
        API.post(postUrl.delete,
            {
                file_id: file_id,
                file_code: file_code,
                file_name: file_name
            }).then(res => {
                if (res.data.status === 200) {
                    this.getFileDetails();
                    this.setState({
                        open: true,
                        errMessage: 'File deleted successfully.',
                        fileDetails: [], fileFound: false
                    })
                }
            }, error => {
                console.error(error);
                this.setState({
                    open: true,
                    errMessage: 'Some error occured'
                })
            });
    }

    onForward(row) {
        this.setState({ selRow: row, isOpenForward: true })
    }

    onForwardClose() {
        this.setState({ selRow: '', isOpenForward: false })
    }

    render() {
        if (this.state.fileFound === false)
            return (<div style={{ marginTop: '20px', marginBottom: '20px', justifyContent: 'center', display: 'flex', fontWeight: 'bold' }}>
                Loading
            </div>)
        return (
            <div>
                <DataTable
                    columns={this.columns}
                    data={this.state.data}
                    customStyles={customStyles}
                    Clicked
                    pagination={true}
                    highlightOnHover={true}
                    striped={true}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                    paginationPerPage={10}
                />
                <Forward isOpenForward={this.state.isOpenForward} selRow={this.state.selRow} onForwardClose={this.onForwardClose.bind(this)} user={this.user} />
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={this.state.open}
                    onClose={this.msgHide}
                    autoHideDuration={5000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.errMessage}</span>}
                />
            </div>
        )
    }
};