import React from 'react';
import { pages } from '../../constants/url';
import $ from 'jquery';
import { browserHistory } from 'react-router';

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
	}

    render() {
        return (
            <section class="home_banner_area">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content">
                                <h2>About Us</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a href="" onClick={() => { this.onClicked(pages.about) }}>About Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="mission_area">{/* style={{backgroundColor:'rgb(226, 233, 242)'}} */}
                    <div class="row m0">
                        <div class="col-lg-6 p0">
                            <div class="left_img"><img src="/kanadnetworks/img/other/KanadIcon_New.png" alt="" style={{ width: '80%', paddingLeft:'70px' }} /></div>
                        </div>
                        <div class="col-lg-6 p0">
                            <div id="at-products">
                                <h3 style={{ marginLeft: '20px', marginTop: '150px' }} >Terakut® is a proud offering of Kanad Networks</h3>
                                {/* <p style={{ marginLeft: '20px', fontWeight: 'bold' }}>Terakut® is a proud offering of Kanad Networks</p> */}
                                <p style={{ marginLeft: '20px', fontWeight: 'bold' }}>About Kanad Networks</p>
                                {/* <p style={{ marginLeft: '20px', fontWeight: 'bold' }}>…Crafting  a digital symphony!</p> */}
                                <p style={{ marginLeft: '20px' }}>We are a technology start-up offering innovative products, solutions and services to a range of industries and domains. Kanad started off with venturing into telecom engineering and has gradually sprouted into various domains</p>
                                <p style={{ marginLeft: '20px', marginBottom: '150px' }}>Headquartered in Delhi, Kanad was founded by a group of IIT and Wharton alumni. Within a short span, it has built a formidable team and carved out a space for itself.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        );

    }
}

export default About;

