import React, { Component } from 'react'
import API from '../../api';
import Loader from '../../components/Loader';
import Snackbar from '@material-ui/core/Snackbar';
import { postUrl, pages } from '../../constants/url';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { browserHistory, Link } from 'react-router';
import Features from './features';

export default class DownloadFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submit: 'Download',
            errMessage: '',
            open: false,
            fileDetails: [],
            fileFound: false
        }
        this.msgHide = this.msgHide.bind(this);
        this.downloadFolder = this.downloadFolder.bind(this);
    }

    componentDidMount() {
        this.getFileDetails();
    }

    getFileDetails() {
        if (this.props.params.file_code.length > 0) {
            API.post(postUrl.download, this.props.params).then(res => {
                if (res.data.data.fileDetail.length > 0) {
                    this.setState({ fileDetails: res.data.data.fileDetail[0], fileFound: true })
                }
                else {
                    this.setState({
                        open: true,
                        errMessage: 'File details not found.'
                    })
                }
                this.setState({ submit: 'Download' });
            }, error => {
                this.setState({ submit: 'Download' });
                this.setState({
                    open: true,
                    errMessage: 'Some error occured'
                })
            });
        }
    }

    msgHide() {
        this.setState({ errMessage: '', open: false })
    }

    onClicked() {
        if (this.state.fileFound) {
            API.post(postUrl.downloadCount, {
                file_id: this.state.fileDetails.file_id,
                file_size: this.state.fileDetails.filesize,
                user_code: this.props.params.user_code
            }).then(res => {
                if (res.data.status === 200) {

                }
            }, error => {
                console.error(error);
                this.setState({
                    open: true,
                    errMessage: 'Some error occured'
                })
            });
        }
    }

    downloadFolder() {
        if (this.state.fileFound) {
            this.setState({ submit: <Loader /> })
            API.post(postUrl.downloadMultiFiles, {
                file_id: this.state.fileDetails.file_id,
                file_size: this.state.fileDetails.filesize,
                user_code: this.props.params.user_code,
                file_code: this.state.fileDetails.filecode,
                file_name: this.state.fileDetails.file_name
            }).then(res => {
                if (res.status === 200) {
                    var url = '/public/files/' + this.state.fileDetails.filecode + "/" + this.state.fileDetails.file_name + ".zip";
                    console.log(url);
                    const element = document.createElement("a");
                    element.href = url;
                    element.download = this.state.fileDetails.file_name + ".zip";
                    document.body.appendChild(element);
                    element.click();
                }
                this.setState({ submit: 'Download' });
            }, error => {
                console.error(error);
                this.setState({
                    open: true,
                    errMessage: 'Some error occured'
                })
            });
        }
    }

    trasferFile() {
        browserHistory.push(pages.home);
    }

    tryRequire(path) {
        try {
            return require(`${path}`);
        } catch (err) {
            return null;
        }
    };

    render() {
        if (this.state.fileFound === true) {
            $('#loader').css({
                display: 'none'
            })
        }
        return (
            <section class="home_banner_area" id="at-download">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide"><img src="/kanadnetworks/img/slider/KanadNetworks-Main1.jpg" alt="" />
                            <div class="slider_text_inner">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="slider_text">
                                                <div className="row contact_form">
                                                    {
                                                        this.state.fileDetails.expire > -1 && this.state.fileDetails.active > 0 && this.state.fileDetails.fileexist === 'true' ?
                                                            <div className="col-md-12">
                                                                <div class="input-group d-flex flex-row" style={{ justifyContent: 'center' }}>
                                                                    <i class="fa fa-download" style={{ fontSize: '80px' }}></i>
                                                                </div>
                                                                <div class="input-group d-flex flex-row" style={{ justifyContent: 'center' }}>
                                                                    <p style={{ fontSize: '20px' }}>Ready when you are</p>
                                                                </div>
                                                                <div class="input-group d-flex flex-row" style={{ justifyContent: 'center', marginTop: '-15px' }}>
                                                                    <p style={{ fontSize: '15px' }}>Transfer expire in {this.state.fileDetails.expire} days</p>
                                                                </div>
                                                                <div class="input-group d-flex flex-row" style={{ justifyContent: 'center' }}>
                                                                    <p>{this.state.fileDetails.description}</p>
                                                                </div>
                                                                <div class="input-group d-flex flex-row" style={{ justifyContent: 'center', fontSize: '15px' }}>
                                                                    <p title={this.state.fileDetails.file_name}>
                                                                        {
                                                                            this.state.fileDetails.file_count > 1 ?
                                                                                "Multiple files or folder"
                                                                                :
                                                                                this.state.fileDetails.file_name.length > 38 ? this.state.fileDetails.file_name.slice(0, 34) + "... ." + this.state.fileDetails.file_name.split('.').pop() : this.state.fileDetails.file_name
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div class="input-group d-flex flex-row" style={{ justifyContent: 'center' }}>
                                                                    <p>{this.state.fileDetails.filesize} (MB)</p>
                                                                </div>
                                                                <div class="input-group d-flex flex-row" style={{ justifyContent: 'center' }}>
                                                                    {
                                                                        this.state.fileDetails.file_count > 1 ?
                                                                            // <button disabled={!this.state.fileFound} className="btn submit_btn" onClick={this.downloadFolder}>{this.state.submit}</button>
                                                                            <Link to={this.state.fileFound ? '/public/files/' + this.state.fileDetails.filecode + "/" + this.state.fileDetails.file_name + ".zip" : ''}
                                                                                className="btn submit_btn" target="_blank" onClick={() => { this.onClicked() }} download>{this.state.submit}
                                                                            </Link>
                                                                            :
                                                                            <Link to={this.state.fileFound ? '/public/files/' + this.state.fileDetails.filecode + "/" + this.state.fileDetails.file_name : ''}
                                                                                className="btn submit_btn" target="_blank" onClick={() => { this.onClicked() }} download>{this.state.submit}
                                                                            </Link>
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="col-md-12">
                                                                <div class="input-group d-flex flex-row" style={{ justifyContent: 'center' }}>
                                                                    <i class="fa fa-file" style={{ fontSize: '80px' }}></i>
                                                                </div>
                                                                <div class="input-group d-flex flex-row" style={{ justifyContent: 'center' }}>
                                                                    <p style={{ fontSize: '20px', marginTop: '10px' }}>Transfer {this.state.fileDetails.expire > 0 ? 'deleted' : 'expired'}</p>
                                                                </div>
                                                                <div class="input-group d-flex flex-row" style={{ justifyContent: 'center', marginTop: '-10px' }}>
                                                                    <p style={{ fontSize: '15px', marginLeft: '25px' }}>Sorry, this transfer has {this.state.fileDetails.expire > 0 ? 'deleted' : 'expired'} and is not available any more</p>
                                                                </div>
                                                                <div class="input-group d-flex flex-row" style={{ justifyContent: 'center' }}>
                                                                    <button className="btn submit_btn" onClick={() => { this.trasferFile() }}>Transfer a file?</button>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="success_area">
                        <div class="row m0 right_dir" id="at-digitalservices">
                            <div class="col-lg-6 p0">
                                <h4 style={{ marginLeft: '20px', justifyContent: 'center', display: 'flex' }}>Share, store, manage & collaborate</h4>
                                <h4 style={{ marginLeft: '20px', justifyContent: 'center', display: 'flex' }} >With elegance & ease!</h4>
                                <h3 style={{ marginLeft: '20px', justifyContent: 'center', display: 'flex' }} >On Safe & Secure Servers located in Indian Territory</h3>
                                <p style={{ marginLeft: '20px' }}>Terakut® offers an elegant and secure environment to let you store, share and manage your files and data - at an incredible speed and ease of use.</p>
                                <p style={{ marginLeft: '20px' }}>Experience global standards of service, offered on Indian servers, built and managed by an Indian organization!</p>
                            </div>
                            <div class="col-lg-6 p0" style={{ marginTop: '50px' }}>
                                {/* <h4 style={{ marginLeft: '100px' }} >Key Features</h4> */}
                                <Features></Features>
                            </div>
                        </div>
                    </section>
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        open={this.state.open}
                        onClose={this.msgHide}
                        autoHideDuration={5000}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{this.state.errMessage}</span>}
                    />
                </div>
            </section>
        )
    }
}