import React from 'react';
import { pages, postUrl } from '../../constants/url';
import { texts, cookies } from '../../constants/strings';
import $ from 'jquery';
import { Logout } from '../Logout';
import Login from '../Login';
import Register from '../Login/Register';
import ForgotPass from '../Login/ForgotPass';
import { style } from '@material-ui/system';
import Cookies from 'universal-cookie';
import { browserHistory } from 'react-router';
import { Snackbar } from '@material-ui/core';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: '',
			IsLoginOpen: false,
			IsRegisterDialogOpen: false,
			isOpenForgotPass: false,
			user: null,
			location: window.location.pathname,
			show: false,
			user_name: '',
			registerDialogForProile: false,
			openSnakbar: false,
			message: '',
			fileUploading: false
		}

		this.handleHomeClick = this.handleHomeClick.bind(this);
		this.handleAboutClick = this.handleAboutClick.bind(this);
		this.handleContactClick = this.handleContactClick.bind(this);
		this.onClicked = this.onClicked.bind(this);
		this.cookies = new Cookies();
		this.onRegisterClose = this.onRegisterClose.bind(this);
		this.onRegisterSuccess = this.onRegisterSuccess.bind(this);
		this.onLoginClose = this.onLoginClose.bind(this);
		this.onSuccess = this.onSuccess.bind(this);
		this.msgHide = this.msgHide.bind(this);

		this.userLogout = this.userLogout.bind(this);
	}

	userLogout() {
		this.setState({ user: null, user_name: '', IsLoginOpen: false }, () => {
			this.render();
		})
		this.props.userLogout(true);
	}

	msgHide() {
		this.setState({
			openSnakbar: false,
			message: '',
		})
	}

	componentDidMount() {
		const user = this.cookies.get(cookies.user);
		if (user) {
			this.setState({ user, user_name: (user.Name === null || user.Name === '' ? user.EmailId : user.Name) })
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.user !== null) {
			const user = nextProps.user;
			if (user) {
				this.setState({ user, user_name: (user.Name === null || user.Name === '' ? user.EmailId : user.Name) })
			}
		}
		if (nextProps.fileUploading !== this.state.fileUploading) {
			this.setState({
				fileUploading: nextProps.fileUploading
			})
		}
	}

	handleHomeClick() {
		if (!this.state.fileUploading) {
			browserHistory.push(pages.home);
		}
	}

	handleAboutClick() {
		if (!this.state.fileUploading) {
			browserHistory.push(pages.about);
		}
	}

	handleContactClick() {
		if (!this.state.fileUploading) {
			browserHistory.push(pages.contacts);
		}
	}
	onClicked(page) {
		if (!this.state.fileUploading) {
			browserHistory.push({
				pathname: page
			})
		}
	}

	onRegisterSuccess(message) {
		this.setState({ openSnakbar: true, message: message, IsRegisterDialogOpen: false })
	}

	onRegisterClose() {
		this.setState({ IsRegisterDialogOpen: false })
	}
	onLoginClose() {
		this.setState({ IsLoginOpen: false })
	}
	onSuccess() {
		const user = this.cookies.get(cookies.user);
		if (user) {
			this.setState({ user, user_name: (user.Name === null || user.Name === '' ? user.EmailId : user.Name) })
			//window.location.reload();
			this.props.userLogin(user);
		}
	}

	onForgotPassOpen() {
		this.setState({ isOpenForgotPass: true, IsLoginOpen: false })
	}
	onForgotPasswordClose() {
		this.setState({ isOpenForgotPass: false })
	}

	onLoginNow() {
		this.setState({ IsRegisterDialogOpen: false, IsLoginOpen: true, isOpenForgotPass: false })
	}
	onSignupNow() {
		this.setState({ IsRegisterDialogOpen: true, IsLoginOpen: false })
	}

	logedOutList() {
		return (
			<ul className="nav navbar-nav menu_nav" id="at-menu">
				{/* <li className={this.state.selected == "home" ? "nav-item active" : "nav-item"} id="li-home"><a className="nav-link" href="" onClick={this.handleHomeClick}>Help</a></li>
				<li className={this.state.selected == "about" ? "nav-item active" : "nav-item"} id="li-about"><a className="nav-link" href="" onClick={this.handleAboutClick}>Plans</a></li> */}

				<li className={this.state.selected == "about" ? "nav-item active" : "nav-item"}>
					<a style={{ cursor: 'pointer' }} className="nav-link" id='header-login-btn'
						onMouseDown={() => {
							this.setState({ IsLoginOpen: true })
						}}>
						Contacts
		            </a>
				</li>
				<li className={this.state.selected == "about" ? "nav-item active" : "nav-item"} id="li-about"><a className="nav-link" href="" onClick={this.handleAboutClick}>About us</a></li>

				<li className={this.state.selected == "about" ? "nav-item active" : "nav-item"}>
					<a style={{ cursor: 'pointer' }} className="nav-link" id='header-login-btn'
						onMouseDown={() => {
							this.setState({ IsLoginOpen: true })
						}}>
						Sign up/Login
		            </a>
				</li>
			</ul>)
	}

	logedInList() {
		return (
			<ul className="nav navbar-nav menu_nav" id="at-menu">
				{/* <li className={this.state.selected == "home" ? "nav-item active" : "nav-item"} id="li-home"><a className="nav-link" href="" onClick={this.handleHomeClick}>Help</a></li>
				<li className={this.state.selected == "about" ? "nav-item active" : "nav-item"} id="li-about"><a className="nav-link" href="" onClick={this.handleAboutClick}>Plans</a></li> */}
				<li className={this.state.selected == "about" ? "nav-item active" : "nav-item"} id="li-about"><a className="nav-link" href="" onClick={this.handleContactClick}>Contacts</a></li>
				<li className={this.state.selected == "about" ? "nav-item active" : "nav-item"} id="li-about"><a className="nav-link" href="" onClick={this.handleAboutClick}>About us</a></li>

				<li className="nav-item submenu dropdown">
					<a href="" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
						<img className="img-avatar" style={{ marginRight: '5px', borderRadius: '10px' }} src="/kanadnetworks/img/user-img.png" alt="" />
						{this.state.user_name}
					</a>
					<ul className="dropdown-menu" style={{ marginTop: '-8px' }}>
						<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.manageFiles) }}><i className="fa fa-upload"></i> Manage files</a></li>
						<li className="nav-item"><a className="nav-link" href=""
							onMouseDown={() => {
								if (!this.state.fileUploading) {
									this.setState({
										IsRegisterDialogOpen: true, registerDialogForProile: true
									})
								}
							}}>
							<i className="fa fa-user"></i> Profile
							</a>
						</li>
						<li className="nav-item"><Logout fileUploading={this.state.fileUploading} userLogout={this.userLogout}><i className="fa fa-lock"></i> Logout</Logout></li>
					</ul>
				</li>
			</ul>)
	}

	render() {
		return (
			<header className="header_area" id="at-header">
				<div className="main_menu">
					<nav className="navbar navbar-expand-lg navbar-light">
						<div className="container box_1620">
							<a className="navbar-brand logo_h" href="" onClick={this.handleHomeClick}>
								<img id="imgLogo" style={{ pointer: 'cursor', width: '85%' }} src="/kanadnetworks/img/Terakut_Logo273X72.png" alt="" />
							</a>
							<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
							<div className="collapse navbar-collapse offset" id="navbarSupportedContent">
								{
									this.state.user !== null ?
										this.logedInList()
										:
										this.logedOutList()
								}
							</div>
						</div>
					</nav>
					<ForgotPass isOpenForgotPass={this.state.isOpenForgotPass} onLoginNow={this.onLoginNow.bind(this)} onForgotPasswordClose={this.onForgotPasswordClose.bind(this)} />
					<Login IsLoginOpen={this.state.IsLoginOpen} onLoginClose={this.onLoginClose.bind(this)} onSuccess={this.onSuccess.bind(this)}
						onSignupNow={this.onSignupNow.bind(this)} onForgotPassOpen={this.onForgotPassOpen.bind(this)} />
					<Register IsRegisterDialogOpen={this.state.IsRegisterDialogOpen} onRegisterClose={this.onRegisterClose} onSuccess={this.onSuccess.bind(this)}
						updateProfile={this.state.registerDialogForProile}
						onLoginNow={this.onLoginNow.bind(this)} onRegisterSuccess={this.onRegisterSuccess} />
				</div>
				<Snackbar
					style={{ zIndex: 1300 }}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					open={this.state.openSnakbar}
					onClose={this.msgHide}
					autoHideDuration={5000}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}
					message={<span id="message-id">{this.state.message}</span>}
				/>
			</header>
		)
	}
}

export default Header;