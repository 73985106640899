import React from 'react';
import { pages, getUrl, postUrl } from '../../constants/url';
import $ from 'jquery';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import Cookies from 'universal-cookie';
import { cookies } from '../../constants/strings';
import API from '../../api';
import DataTable, { memoize } from 'react-data-table-component';
import Create from './create';
import PreLoader from '../../components/PreLoader';

const customStyles = {
    rows: {
        style: {
            minHeight: '40px', // override the row height
            maxHeight: '40px',
            striped: true
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            fontWeight: 'bold',
            fontSize: '15px'
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            fontSize: '14px',
            center: true
        },
    },
};

class Contacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errMessage: '',
            open: false,
            users: [],
            dataFound: false,
            search: '',
            selRow: '',
            IsDialogOpen: false,
        }
        this.user = null
        this.cookies = new Cookies();
        this.msgHide = this.msgHide.bind(this);
        this.onDialogClose = this.onDialogClose.bind(this);

        this.columns = [
            {
                name: 'Email',
                selector: 'emailid',
                sortable: true,
                ignoreRowClick: true,
                allowOverflow: true,
                cell: row => <div title={row.emailid} style={{ fontWeight: 'bold' }}>{row.emailid}</div>,
            },
            {
                name: 'Name',
                selector: 'name',
                wrap: true,
                sortable: true,
                format: row => <div title={row.name}> {row.name} </div>,
            },
            {
                // name: <div style={{ marginLeft: '10px' }}><button onClick={() => this.add()} className="btn btn-primary" style={{ width: '50px', marginLeft: '1px', padding: '3px 10px' }}
                //     title='Add'>Add</button></div>,
                grow: 0,
                ignoreRowClick: true,
                cell: row => <div style={{ marginLeft: '10px' }}>
                    <button onClick={() => this.edit(row)} className="btn btn-primary" style={{ width: '40px', marginLeft: '1px', padding: '3px 10px' }}
                        title='Edit'><i className='fa fa-edit'></i></button>
                </div>,
            },
            // {
            //     grow: 0,
            //     ignoreRowClick: true,
            //     cell: row => <div>
            //         <button onClick={() => this.delete(row)} className="btn btn-danger" style={{ width: '40px', marginLeft: '10px', padding: '3px 10px' }}
            //             title='Delete'><i className='fa fa-trash'></i></button>
            //     </div>,
            // },
        ];
    }

    componentDidMount() {
        const user = this.cookies.get(cookies.user);
        if (user) {
            this.user = user;
            this.getUsers();
        }
        $('#loader').css({
            display: 'none'
        })
    }

    edit(row) {
        this.setState({ selRow: row, IsDialogOpen: true })
    }

    add() {
        this.setState({ IsDialogOpen: true })
    }

    onDialogClose() {
        this.setState({ selRow: '', IsDialogOpen: false })
    }

    onSuccess(message, users) {
        this.setState({
            selRow: '',
            open: true,
            errMessage: message,
            IsDialogOpen: false,
            users: users
        })
    }

    getUsers() {
        API.post(getUrl.usersBySender, { sender_email: this.user.EmailId.trim() }).then(res => {
            if (res.data.status === 200) {
                if (res.data.data.users.length > 0)
                    this.setState({ users: res.data.data.users, dataFound: true })
                else
                    this.setState({
                        dataFound: true
                    })
            }
            else {
                this.setState({
                    open: true,
                    errMessage: 'Some error occured',
                    dataFound: true
                })
            }
        }, error => {
            console.error(error);
            // this.setState({
            //     open: true,
            //     errMessage: 'Some error occured'
            // })
        });
    }

    msgHide() {
        this.setState({ errMessage: '', open: false })
    }

    render() {
        if (this.state.dataFound === false)
            return (<section class="home_banner_area"><div style={{ marginTop: '20px', marginBottom: '20px', justifyContent: 'center', display: 'flex', fontWeight: 'bold' }}>
                Loading...
            </div></section>)
        return (
            <section class="home_banner_area">
                <div style={{ marginTop: '5px', paddingRight: '20px', paddingLeft: '20px' }}>
                    <DataTable
                        columns={this.columns}
                        data={this.state.users}
                        customStyles={customStyles}
                        Clicked
                        pagination={true}
                        highlightOnHover={true}
                        striped={true}
                        paginationComponentOptions={{
                            noRowsPerPage: true
                        }}
                        paginationPerPage={10}
                    />
                    <Create IsDialogOpen={this.state.IsDialogOpen} selRow={this.state.selRow}
                        onDialogClose={this.onDialogClose}
                        onSuccess={this.onSuccess.bind(this)} user={this.user} />
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        open={this.state.open}
                        onClose={this.msgHide}
                        autoHideDuration={5000}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}>
                        <SnackbarContent style={{
                            backgroundColor: 'black',
                        }}
                            message={<span id="message-id">{this.state.errMessage}</span>}
                        />
                    </Snackbar>
                </div>
            </section>
        );
    }
}

export default Contacts;

