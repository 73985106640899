import React from 'react';
import Cookies from 'universal-cookie';
import { postUrl, pages } from '../constants/url';
import { cookies } from '../constants/strings';
import { browserHistory } from 'react-router';

class Logout extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			fileUploading: false
		}
		this.logoutEvent = this.logoutEvent.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.fileUploading !== this.state.fileUploading) {
			this.setState({
				fileUploading: nextProps.fileUploading
			})
		}
	}

	logoutEvent(e) {
		if (!this.state.fileUploading) {
			e.preventDefault();
			logout();
			this.props.userLogout();
		}
	}
	render() {
		return (
			<a className="nav-link" href="" onClick={this.logoutEvent}>{this.props.children}</a>
		)
	}
}

const logout = function () {
	var cookie = new Cookies();
	const user = cookie.get(cookies.user);
	Object.values(cookies).map(x => {
		return cookie.remove(x, { path: '/' })
	});
	if (user) {
		if (browserHistory.getCurrentLocation().pathname === pages.myProfile || browserHistory.getCurrentLocation().pathname === pages.manageFiles
			|| browserHistory.getCurrentLocation().pathname === pages.contacts) {
			window.location.replace(pages.home);
		}
	}
}

export { Logout, logout };