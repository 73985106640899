const messages = {
	error: 'Some Error occured. Please contact support',
	loginSuccess: 'Log In Successful',
	otpSent: (phone) => ('OTP sent to ' + phone),
}

const texts = {
	company: 'Zillion',

	submit: 'Submit',
	phone: 'Phone',
	otp: 'OTP',
	search: 'Search',
	searchPlaceholder: 'Search by City, Locality, Coworking Brand, Metro Line or Metro station',
	telephone: '9999999999',
	shortlist: 'Shortlist',
	shortlisted: 'Shortlisted',

	facebook: 'https://www.facebook.com',
	twitter: 'https://www.twitter.com',
	gmail: 'https://www.gmail.com',
	whatsapp: 'tel:9999999999',
	linkedin: 'https://www.linkedin.com'
}

const cookies = {
	user: 'kanad_transfer_user'
}
const dataUploadLimit = {
	size: 3,
	file_count: 60000,
	size_message: 'Uploaded data exceeded limit of 3 GB.',
	count_message: 'Uploaded file count exceeded limit of 60000.'
}

const keys = {
	google: "375782218881-abrdn97j6nurcr36geii2aijt1saij95.apps.googleusercontent.com",
	facebook: "202999481485212",
	googleMap: 'AIzaSyBJ3q6w3hiHe_MIbB1Jy31bGOwL8LYlwJw',
}

export { messages, texts, cookies, keys, dataUploadLimit };