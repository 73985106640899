const pages = {
	home: '/',
	download: '/download',
	delete: '/delete',
	contacts: '/contacts',
	about: '/about-us',
	all: '*',
	notFound: '/404',
	error: '/error-occured',
	myProfile: '/profile',
	manageFiles: '/manage-files',
	resetPass: '/resetpass'
}

const uploadUrl = (u) => {
	var userUrl = '/upload/:email_from/:email_to/:message';
	return userUrl.replace(':email_from', u.email_from).replace(':email_to', u.email_to).replace(':message', u.message);
};

const postUrl = {
	contactUsEmail: '/contact-us/email',
	uploadFile: (u) => { return (uploadUrl(u)) },
	download: '/download',
	forward: '/forward',
	downloadCount: '/download/add-download-count',
	downloadMultiFiles: '/download/multiple-files',
	delete: '/delete',
	register: '/register',
	login: '/login',
	sendOTP: '/login/send-login-otp',
	forgotPass: '/login/forgot-pass',
	resetpass: '/login/resetpass',
	checkLink: '/login/checkLink',
	updateProfile: '/register/update-profile',
	sendEmailVerifyOTP: '/validate-email/send-otp',
	verifyEmailOTP: '/validate-email/verify-otp',
	addContact: '/contacts/add',
	updateContact: '/contacts/update'
}

const getUrl = {
	countrys: '/common/country',
	getUploadFilesByUser: '/download/uploaded-files-by-user',
	getReceivedFilesByUser: '/download/received-files-by-user',
	usersBySender: '/contacts/by-sender',
}

export { pages, postUrl, getUrl }; 