import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Cookies from 'universal-cookie';
import Login from './index';
import API from '../../api';
import Loader from '../Loader';
import { getUrl, postUrl, pages } from '../../constants/url';
import { cookies } from '../../constants/strings';
import $ from 'jquery';
import { browserHistory } from 'react-router';

class ResetPass extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			passError: '',
			conPassError: '',
			password: '',
			re_password: '',
			display: <Loader />,
			open: false,
			errMessage: '',
			submit: 'Reset'
		}

		this.passChange = this.passChange.bind(this)
		this.conPassChange = this.conPassChange.bind(this)
		this.nextClick = this.nextClick.bind(this)

		this.cookies = new Cookies();
		this.msgHide = this.msgHide.bind(this);
	}

	msgHide() {
		this.setState({ errMessage: '', open: false })
	}

	componentDidMount() {
		$('#loader').css({
			display: 'none'
		})
		browserHistory.push(pages.resetPass + '/' + this.props.params.email + '/' + this.props.params.ref);
		this.checkLink();
	}

	checkLink() {
		var data = {
			email: this.props.params.email,
			otp: this.props.params.ref
		}
		
		API.post(postUrl.checkLink, data)
			.then(res => {				
				if (res.data.status === 200) {
					this.setState({
						display: 'none'
					})
				} else {
					browserHistory.push(pages.notFound);					
				}
			}).catch(err => {
				console.log(err)
			});
	}

	passChange(event) {
		const { target: { value } } = event;
		this.setState({
			password: value,
			passError: '',
		})
	}

	conPassChange(event) {
		const { target: { value } } = event;
		this.setState({
			re_password: value,
			conPassError: '',
		})
	}

	onKeyPress(e) {
		if (e.key === 'Enter') {
			this.nextClick();
		}
	}

	nextClick() {
		if (this.state.password.trim().length == 0) {
			this.setState({ conPassError: 'Please enter password' });
			return          
        }
        else if (this.state.password.trim().length < 6) {
			this.setState({ conPassError: 'Password length should be more then 5 characters.' });
			return          
        }
		else if (this.state.password !== this.state.re_password) {
			this.setState({ conPassError: 'Passwords dont match' });
			return
		} else {
			const user = {
				email: this.props.params.email,
				password: this.state.password
			}
			const url = postUrl.resetpass + '/' + this.props.params.email + '/' + this.props.params.ref
			
			this.setState({ submit: <Loader /> })
			API.post(url, user).then(res => {
				if (res.data.status === 200) {
					this.setState({					
						open: true,
						errMessage: 'Password Reset Successfully.',
						submit: 'Reset'
					})
					this.cookies.remove(cookies.user);
					this.props.router.push(pages.home)
				} else {
					this.setState({
						open: true,
						errMessage: res.data.error,
						submit: 'Reset'
					})
				}
			}).catch(function (err) {
				console.log(err)
			})
		}
	}

	render() {
		return (
			<section class="home_banner_area" id="at-home-banner">
				<div class="swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide"><img src="/kanadnetworks/img/slider/KanadNetworks-Main1.jpg" alt="" />
							<div class="slider_text_inner">
								<div class="container">
									<div class="row">
										<div class="col-lg-4">
											<div class="slider_text">
												<div className="row contact_form">
													<div className="at-modalcontent modal-content">
														<div className="at-popuptitle">
															<h4>Reset Password</h4>
														</div>
														<div className="modal-body" style={{ padding: 15, paddingTop: 15 }}>
															<div>
																<input type="password" className="form-control" id="outlined-password" name="outlined-password"
																	value={this.state.password}
																	onKeyPress={(e) => this.onKeyPress(e)}
																	onChange={(e) => this.passChange(e)}
																	placeholder="Password*" />
																<p style={{ fontSize: '12px' }}></p>
															</div>
															<div>
																<input type="password" className="form-control" id="outlined-re-password" name="outlined-re-password"
																	value={this.state.re_password} onKeyPress={(e) => this.onKeyPress(e)}
																	onChange={(e) => this.conPassChange(e)}
																	placeholder="Confirm Password*" />
																<p style={{ fontSize: '12px' }}>{this.state.conPassError}</p>
															</div>
															<div className="form-group at-btnarea">
																<button className="btn submit_btn" onClick={() => this.nextClick()}>{this.state.submit}</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Snackbar
						anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
						open={this.state.open}
						onClose={this.msgHide}
						autoHideDuration={5000}
						ContentProps={{
							'aria-describedby': 'message-id',
						}}
						message={<span id="message-id">{this.state.errMessage}</span>}
					/>
				</div>
			</section>
		);
	}
}

export default ResetPass;