import React, { Component } from 'react'
import { browserHistory } from 'react-router';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';

export default class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        const settings = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            appendDots: dots => {
                return <MagicSliderDots style={{ width: '50%', marginBottom: '27px' }}
                    dots={dots} numDotsToShow={2} dotWidth={30} />;
            }
        };

        return (
            <div id="features-ItemsCarousel">
                <Slider {...settings}>
                    <div class="mission_text">
                        <h5>Unlimited sharing</h5>
                        <span>Yes, share as much as you please!</span>
                        <h5 style={{ marginTop: '15px' }}>Incredible speeds.</h5>
                        <span>Experience it, to believe it!</span>
                        <h5 style={{ marginTop: '15px' }}>No bandwidth restriction</h5>
                        <span>Absolutely NO restrictions!</span>
                        <h5 style={{ marginTop: '15px' }}>Supports single files as large as 3gb</h5>
                        <span>Yes, all those mega files too!</span>
                        <h5 style={{ marginTop: '15px' }}>Supports multi-file uploads.</h5>
                        <span>Select up to xx files for a simultaneous upload!</span>
                        <h5 style={{ marginTop: '15px' }}>Supports folder uploads.</h5>
                        <span>Need to upload an entire folder? Do that in a single shot!</span>
                    </div>
                    <div class="mission_text">
                        <h5>Supports file forwards</h5>
                        <span>Forgot to share it with someone you intended to? A single click forward will fix that!</span>
                        <h5 style={{ marginTop: '15px' }}>Auto destruct/delete in 7 days</h5>
                        <span>Your files will be securely deleted after 7 days!</span>
                        <h5 style={{ marginTop: '15px' }}>Manage your uploads with ease</h5>
                        <span>Managing your content was never easier!</span>
                        <h5 style={{ marginTop: '15px' }}>All our servers are physically located in India</h5>
                        <span>Yes, we boast of offering world-class experience from Indian territory </span>
                        <h5 style={{ marginTop: '15px' }}>Indigenously developed and maintained in India</h5>
                        <span>Yes, 100% - from the soil of India</span>
                    </div>
                </Slider>
            </div>
        )
    }
}