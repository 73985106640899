import React from 'react';

class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}
	
	render() {

		return (
			<footer className="footer-area" id="footer">
				<div className="border_line"></div>
				<div className="container">
					<div className="row footer-bottom d-flex justify-content-between align-items-center">
						<p className="col-lg-8 col-md-8 footer-text m-0">
							Copyright &copy;{new Date().getFullYear()} <a href="https://www.kanadnetworks.com" target={"_blank"}>Kanad Networks Pvt Ltd</a>
						</p>
					</div>
				</div>
			</footer>
		);
	}
}

export default Footer;

