import React from 'react';

class PreLoader extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div id="loader2">
                <div class="preloader d-flex align-items-center justify-content-center">
                    <div class="preloader-inner position-relative">
                        <div class="preloader-circle"></div>
                        <div class="preloader-img pere-text">
                            <img src="/kanadnetworks/img/fevicon.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PreLoader;