import React from 'react';
import { pages } from '../../constants/url';
import UploadFile from './uploadfile';
import $ from 'jquery';
import PreLoader from '../../components/PreLoader';
import { browserHistory } from 'react-router';

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		}

		this.onLogin = this.onLogin.bind(this);
		this.onUploading = this.onUploading.bind(this);
	}

	componentDidMount() {
		$('#loader').css({
			display: 'none'
		})
		$('#loader2').css({
			visibility: 'hidden'
		})
	}

	onLogin(user) {
		this.props.userLogin(user);
	}

	onUploading(uploading) {
		this.props.onUploading(uploading);
	}

	handleAboutClick() {
		browserHistory.push(pages.about);
	}

	render() {
		return (
			<div>
				<PreLoader></PreLoader>
				<UploadFile onLogin={this.onLogin} onUploading = {this.onUploading} user={this.props.user} logout={this.props.logout}></UploadFile>
			</div>
		)
	}
}

export default Home;


