import React from 'react';
import { Route, Router } from 'react-router';
import { pages } from './constants/url';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './views/Home';
import NotFound from './views/NotFound';
import About from './views/About';
import Contacts from './views/Contacts';
import DownloadFile from './views/Home/downloadfile';
import DeleteFile from './views/Home/deletefile';
import ResetPass from './components/Login/ResetPass';
import ManageFiles from './views/ManageFiles';

class Routes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
			logout: false,
			fileUploading: false
		}

		this.userLogin = this.userLogin.bind(this);
		this.userLogout = this.userLogout.bind(this);
		this.onUploading = this.onUploading.bind(this);
	}

	userLogin(user) {
		this.setState({
			user, logout: false
		})
	}

	userLogout(logout) {
		this.setState({
			logout, user: null
		})
	}

	onUploading(fileUploading) {
		this.setState({ fileUploading })
	}

	render() {
		return (
			<div>
				<Header user={this.state.user} fileUploading={this.state.fileUploading} userLogin={this.userLogin} userLogout={this.userLogout} />
				<Router {...this.props}>
					<Route exact path={pages.home} component={() => <Home onUploading={this.onUploading} userLogin={this.userLogin} user={this.state.user} logout={this.state.logout} />} />
					<Route path={pages.download + '/:file_code/:user_code'} component={DownloadFile} />
					<Route path={pages.delete + '/:file_code/:user_code'} component={DeleteFile} />
					<Route path={pages.resetPass + '/:email/:ref'} component={ResetPass} />
					<Route path={pages.about} component={About} />
					<Route path={pages.contacts} component={Contacts} />
					<Route path={pages.manageFiles} component={ManageFiles} />
					<Route path={pages.all} component={NotFound} />
					<Route path={pages.error} component={NotFound} />
				</Router>
				<Footer />
			</div>
		)
	}
}

export default Routes;