import React from 'react'
import { Dialog, Button, Snackbar } from '@material-ui/core';
import Cookies from 'universal-cookie';
import API from '../../api';
import { getUrl, postUrl } from '../../constants/url';
import Facebook from './Facebook';
import Google from './Google';
import TextField from '@material-ui/core/TextField';
import validator from 'validator';
import { cookies } from '../../constants/strings';
import Loader from '../Loader';
import { logout } from '../Logout';

export default class Register extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            submit: this.props.updateProfile === true ? 'Update' : 'Register',
            formErrors: {
                country: '',
                email: '',
                pass: '',
                name: ''
            },
            name: '',
            country: 0,
            emailAddess: '',
            password: '',
            re_password: '',
            IsDialogOpen: this.props.IsRegisterDialogOpen,

            openSnakbar: false,
            message: '',            
            dialogWidth: window.innerWidth > 770 ? '39%' : (window.innerWidth > 760 ? '60%' : '109%'),
            dialogLeft: window.innerWidth > 770 ? '30%' : (window.innerWidth > 760 ? '20%' : '-5%'),
            countrys: [],
            user: []
        }
        this.cookies = new Cookies();
        this.SuccessResponse = this.SuccessResponse.bind(this);
        this.msgHide = this.msgHide.bind(this);
        this.developmentClick = this.developmentClick.bind(this);
    }

    componentDidMount() {
        this.getCountrys();
    }

    getCountrys() {
        API.get(getUrl.countrys)
            .then(res => {
                if (res.data.status !== 200) {
                    this.setState({ message: res.data.error, openSnakbar: true })
                } else {
                    if (res.data.data[0] !== undefined) {
                        this.setState({
                            countrys: res.data.data,
                            country: res.data.data[0].country_id
                        })
                    }
                }
            }).catch(function (err) {
                this.setState({ message: err, openSnakbar: true })
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.IsRegisterDialogOpen !== this.props.IsRegisterDialogOpen) {
            if (this.props.updateProfile === true) {
                const user = this.cookies.get(cookies.user);
                if (user) {
                    this.setState({
                        user, emailAddess: user.EmailId, password: user.Password,
                        re_password: user.Password, country: user.Country_Id, name: user.Name
                    })
                }
            }
            this.setState({ IsDialogOpen: this.props.IsRegisterDialogOpen, submit: this.props.updateProfile === true ? 'Update' : 'Register' })
        }
    }

    msgHide() {
        this.setState({
            openSnakbar: false,
            message: '',
        })
    }

    verify() {
        const errors = this.state.formErrors;
        let ver = true;

        if (this.state.country == 0) {
            errors.country = 'Country is compulsory';
            ver = false;
        }
        if (this.state.name.trim().length == 0) {
            errors.name = 'User name is required.';
            ver = false;
        }
        if (this.state.emailAddess.trim().length == 0) {
            errors.email = 'Please enter email';
            ver = false;
        }
        else if (!validator.isEmail(this.state.emailAddess)) {
            errors.email = 'Invalid email';
            ver = false;
        }

        if (this.state.password.trim().length == 0 && this.state.user.LoginType == 1) {
            errors.pass = 'Please enter password';
            ver = false;
        }
        else if (this.state.password.trim().length < 6 && this.state.user.LoginType == 1) {
            errors.pass = 'Password length should be more then 5 characters.';
            ver = false;
        }
        else if (this.state.password !== this.state.re_password && this.state.user.LoginType == 1) {
            errors.pass = 'Password should be same';
            ver = false;
        }

        this.setState({ formErrors: errors });
        return ver;
    }

    onRegister() {
        if (this.verify()) {
            const user = {
                country: this.state.country,
                email: this.state.emailAddess,
                password: this.state.password,
                user_id: this.state.user ? this.state.user.User_Id : 0,
                login_type: 1,
                name: this.state.name
            }
            this.setState({ submit: <Loader /> })
            if (this.props.updateProfile === false) {
                API.post(postUrl.register, user).then(res => {
                    if (res.data.status === 200) {
                        this.setState({
                            openSnakbar: true, message: res.data.error, emailAddess: '',
                            password: '', re_password: '', IsDialogOpen: false, submit: 'Register'
                        })
                        this.props.onRegisterSuccess(res.data.error);
                    } else {
                        this.setState({ openSnakbar: true, message: res.data.error, submit: 'Register' })
                    }
                })
            }
            else {
                API.post(postUrl.updateProfile, user).then(res => {
                    if (res.data.status === 200) {
                        this.setState({
                            openSnakbar: true, message: res.data.error, IsDialogOpen: false, submit: 'Update'
                        })

                        logout();
                    } else {
                        this.setState({ openSnakbar: true, message: res.data.error, submit: 'Register' })
                    }
                })
            }
        }
    }

    SuccessResponse(response, type) {
        var userData = {
            name: null,
            password: '',
            email: type,
            country: 1,
            login_type: 1
        }
        if (type == 3) { // Google
            userData.name = response.profileObj.name
            userData.email = response.profileObj.email
            userData.login_type = 3;
        } else if (type == 2) {  // facebook
            userData.name = response.name
            userData.email = response.email
            userData.login_type = 2;
        }
        API.post(postUrl.register, userData).then(response => {
            if (response.data.status === 200) {
                var user = response.data.data.user;
                this.cookies.set(cookies.user, user[0], { path: '/' });

                this.setState({ IsDialogOpen: false });
                this.props.onSuccess();
            } else {
                this.setState({ open: true, message: response.data.error })
            }
        })
    }

    emailChange(event) {
        const { target: { value } } = event;
        var x = this.state.formErrors
        x.email = ''
        this.setState(p => ({
            emailAddess: value,
            formErrors: x
        }))
    }

    countryChange(event) {
        const { target: { value } } = event;
        const formErrors = this.state.formErrors;
        formErrors.country = '';
        this.setState({
            country: value, formErrors
        })
    }

    rePassChange(event) {
        const { target: { value } } = event;
        const formErrors = this.state.formErrors;
        formErrors.pass = '';
        this.setState({
            re_password: value, formErrors
        })
    }

    nameChange(event) {
        const { target: { value } } = event;
        var x = this.state.formErrors
        x.name = ''
        this.setState(p => ({
            name: value,
            formErrors: x
        }))
    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            this.onRegister();
        }
    }

    developmentClick() {
        this.setState({ message: 'Due to unavailablity of domain name, this functionality is disabled', openSnakbar: true })
    }

    render() {
        return (
            <div id="at-register">
                <Dialog open={this.state.IsDialogOpen} style={{ width: this.state.dialogWidth, left: this.state.dialogLeft, maxWidth: "none" }} onBackdropClick={() => {
                    this.setState({ IsDialogOpen: false, name: '', emailAddess: '', password: '', re_password: '', formErrors: { name: '', email: '', pass: '' } })
                    this.props.onRegisterClose()
                }}>
                    <div className="at-modalcontent modal-content" style={{maxHeight:'100%'}}>
                        <div className="at-popuptitle">
                            {this.props.updateProfile === true ? <h4>Update Profile</h4> : <h4>Register</h4>}
                            <a className="at-closebtn close" onClick={() => {
                                this.setState({ IsDialogOpen: false, emailAddess: '', password: '', re_password: '', formErrors: { name: '', email: '', pass: '' } })
                                this.props.onRegisterClose()
                            }} ><i className="lnr lnr-cross" data-dismiss="modal"></i></a>
                        </div>
                        <div className="modal-body" style={{ padding: 15, paddingTop: 0 }}>
                            <div style={{ marginTop: '5px' }}>
                                <select style={{ fontSize: '15px' }} className="form-control" name="country" onChange={(e) => this.countryChange(e)} value={this.state.country}>
                                    <option value="0">Select country</option>
                                    {
                                        this.state.countrys.map(c => (
                                            <option value={c.country_id} key={'country_' + c.country_id}>{c.countryname}</option>
                                        ))
                                    }
                                </select>
                                <p style={{ fontSize: '12px' }}>{this.state.formErrors.country}</p>
                            </div>
                            <div style={{ marginTop: '5px' }}>
                                <input type="text" className="form-control" id="outlined-name" name="outlined-name"
                                    value={this.state.name} onChange={(e) => this.nameChange(e)}
                                    placeholder="Name*" style={{ fontSize: '15px' }} />
                                <p style={{ fontSize: '13px' }}>{this.state.formErrors.name}</p>
                            </div>
                            <div style={{ marginTop: '5px' }}>
                                <input type="email" className="form-control" id="outlined-email" name="outlined-email"
                                    value={this.state.emailAddess} onChange={(e) => this.emailChange(e)}
                                    placeholder="Email*" style={{ fontSize: '15px' }} disabled={this.props.updateProfile} />
                                <p style={{ fontSize: '13px' }}>{this.state.formErrors.email}</p>
                            </div>
                            <div>
                                <input type="password" className="form-control" id="outlined-password" name="outlined-password"
                                    value={this.state.password}
                                    onKeyPress={(e) => this.onKeyPress(e)}
                                    onChange={(e) => this.setState({ password: e.target.value })}
                                    placeholder="Password*" style={{ fontSize: '15px' }} />
                                <p style={{ fontSize: '12px' }}></p>
                            </div>
                            <div>
                                <input type="password" className="form-control" id="outlined-re-password" name="outlined-re-password"
                                    value={this.state.re_password} onKeyPress={(e) => this.onKeyPress(e)}
                                    onChange={(e) => this.rePassChange(e)}
                                    placeholder="Retype Password*" style={{ fontSize: '15px' }} />
                                <p style={{ fontSize: '12px' }}>{this.state.formErrors.pass}</p>
                            </div>
                            <div className="form-group at-btnarea">
                                <button className="btn submit_btn" onClick={() => this.onRegister()}>{this.state.submit}</button>
                            </div>
                            {this.props.updateProfile === true ? <div></div> :
                                <div>
                                    <span className="at-optionsbar" style={{ marginTop: 5, marginBottom: 10 }}><em>or</em></span>
                                    <div className="at-loginicon">
                                        <ul>
                                            <li><Facebook onFacebookSuccessResponse={this.SuccessResponse.bind(this)} /></li>
                                            <li><Google onGoogleSuccessResponse={this.SuccessResponse} /></li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                        {this.props.updateProfile === true ? <div style={{ width: '500px' }}></div> :
                            <div className="modal-footer">
                                <div className="at-popup-footerterms" style={{ paddingTop: 10, paddingBottom: 10 }}>
                                    <span>By signing in  you agree to these <a> Terms &amp; Conditions</a> &amp; consent to<a href="javascript:void(0);"> Cookie Policy &amp; Privacy Policy.</a></span>
                                </div>
                                <div className="at-loginfooterinfo">
                                    <a onClick={() => this.props.onLoginNow()} style={{ cursor: 'pointer' }}><em>Have Account?</em> Login Now</a>
                                    {/* <a href="javascript:;" class="at-forgot-password">Forgot Your Password?</a> */}
                                </div>
                            </div>
                        }
                    </div>
                    <Snackbar
                        style={{ zIndex: 1300 }}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        open={this.state.openSnakbar}
                        onClose={this.msgHide}
                        autoHideDuration={5000}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{this.state.message}</span>}
                    />
                </Dialog>
            </div>
        )
    }
}
