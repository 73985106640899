import React from 'react'
import { Dialog, Button, Snackbar } from '@material-ui/core';
import Cookies from 'universal-cookie';
import API from '../../api';
import { getUrl, postUrl } from '../../constants/url';
import validator from 'validator';
import { cookies } from '../../constants/strings';
import Loader from '../../components/Loader';
import { logout } from '../../components/Logout';

export default class Create extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            submit: this.props.selRow ? 'Update' : 'Add',
            formErrors: {
                email: '',
                name: ''
            },
            name: this.props.selRow ? this.props.selRow.name : '',
            emailAddess: this.props.selRow ? this.props.selRow.emailid : '',
            IsDialogOpen: this.props.IsDialogOpen,
            openSnakbar: false,
            message: '',
            user_id: this.props.selRow ? this.props.selRow.user_id : 0,
            logedin_user: this.props.user ? this.props.user.EmailId : ''
        }
        this.cookies = new Cookies();
        this.msgHide = this.msgHide.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.IsDialogOpen !== this.props.IsDialogOpen) {
            this.setState({
                IsDialogOpen: this.props.IsDialogOpen,
                submit: this.props.selRow ? 'Update' : 'Add',
                name: this.props.selRow ? this.props.selRow.name : '',
                emailAddess: this.props.selRow ? this.props.selRow.emailid : '',
                user_id: this.props.selRow ? this.props.selRow.user_id : 0,
                logedin_user: this.props.user ? this.props.user.EmailId : ''
            })
        }
    }

    msgHide() {
        this.setState({
            openSnakbar: false,
            message: '',
        })
    }

    verify() {
        const errors = this.state.formErrors;
        let ver = true;

        if (this.state.name.trim().length == 0) {
            errors.name = 'User name is required.';
            ver = false;
        }
        if (this.state.emailAddess.trim().length == 0) {
            errors.email = 'Please enter email';
            ver = false;
        }
        else if (!validator.isEmail(this.state.emailAddess)) {
            errors.email = 'Invalid email';
            ver = false;
        }

        this.setState({ formErrors: errors });
        return ver;
    }

    onRegister() {
        if (this.verify()) {
            const user = {
                email: this.state.emailAddess,
                user_id: this.state.user_id,
                name: this.state.name,
                country: 1,               
                password: '',               
                login_type: 1,
                sender_email: this.state.logedin_user
            }
            this.setState({ submit: <Loader /> })
            if (this.props.selRow) {
                API.post(postUrl.updateContact, user).then(res => {
                    if (res.data.status === 200) {
                        this.setState({
                            openSnakbar: true, message: res.data.error, IsDialogOpen: false, submit: 'Update'
                        })
                        this.props.onSuccess(res.data.error, res.data.data.users);
                    } else {
                        this.setState({ openSnakbar: true, message: res.data.error, submit: 'Update' })
                    }
                })
            }
            else {
                API.post(postUrl.addContact, user).then(res => {
                    if (res.data.status === 200) {
                        this.setState({
                            openSnakbar: true, message: res.data.error, emailAddess: '',
                            password: '', re_password: '', IsDialogOpen: false, submit: 'Add'
                        })
                        this.props.onSuccess(res.data.error, res.data.data.users);
                    } else {
                        this.setState({ openSnakbar: true, message: res.data.error, submit: 'Add' })
                    }
                })
            }
        }
    }

    emailChange(event) {
        const { target: { value } } = event;
        var x = this.state.formErrors
        x.email = ''
        this.setState(p => ({
            emailAddess: value,
            formErrors: x
        }))
    }

    nameChange(event) {
        const { target: { value } } = event;
        var x = this.state.formErrors
        x.name = ''
        this.setState(p => ({
            name: value,
            formErrors: x
        }))
    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            this.onRegister();
        }
    }

    render() {
        return (
            <div>
                <Dialog open={this.state.IsDialogOpen} onBackdropClick={() => {
                    this.setState({ IsDialogOpen: false, name: '', emailAddess: '', formErrors: { name: '', email: '' } })
                    this.props.onDialogClose()
                }}>
                    <div className="at-modalcontent modal-content" style={{ maxHeight: '100%', minWidth: '280px' }}>
                        <div className="at-popuptitle">
                            {this.props.selRow ? <h4>Update</h4> : <h4>Add</h4>}
                            <a className="at-closebtn close" onClick={() => {
                                this.setState({ IsDialogOpen: false, emailAddess: '', name: '', formErrors: { name: '', email: '' } })
                                this.props.onDialogClose()
                            }} ><i className="lnr lnr-cross" data-dismiss="modal"></i></a>
                        </div>
                        <div className="modal-body" style={{ padding: 15, paddingTop: 0 }}>
                            <div style={{ marginTop: '5px' }}>
                                <input type="text" className="form-control" id="outlined-name" name="outlined-name"
                                    value={this.state.name} onChange={(e) => this.nameChange(e)}
                                    placeholder="Name*" style={{ fontSize: '15px' }} />
                                <p style={{ fontSize: '13px' }}>{this.state.formErrors.name}</p>
                            </div>
                            <div style={{ marginTop: '5px' }}>
                                <input type="email" className="form-control" id="outlined-email" name="outlined-email"
                                    value={this.state.emailAddess} onChange={(e) => this.emailChange(e)}
                                    placeholder="Email*" style={{ fontSize: '15px' }} disabled={this.props.selRow} />
                                <p style={{ fontSize: '13px' }}>{this.state.formErrors.email}</p>
                            </div>
                            <div className="form-group at-btnarea">
                                <button className="btn submit_btn" onClick={() => this.onRegister()}>{this.state.submit}</button>
                            </div>
                        </div>
                    </div>
                    <Snackbar
                        style={{ zIndex: 1300 }}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        open={this.state.openSnakbar}
                        onClose={this.msgHide}
                        autoHideDuration={5000}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{this.state.message}</span>}
                    />
                </Dialog>
            </div>
        )
    }
}
