import React, { Component } from 'react'
import { Dialog } from '@material-ui/core';
import API from '../api';
import { getUrl, postUrl } from '../constants/url';
import Snackbar from '@material-ui/core/Snackbar';

export default class DlgContacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            selEmails: this.props.selEmails.length > 0 ? this.props.selEmails.split(',') : [],
            errMessage: '',
            open: false,
            isOpenDialog: this.props.isOpenDialog,
            sender_email: this.props.sender_email,
            dataFound: false,
            search: '',
        };

        this.msgHide = this.msgHide.bind(this);
    }

    getUsers() {
        if (this.state.sender_email.trim() === '') {
            return;
        }
        API.post(getUrl.usersBySender, { sender_email: this.state.sender_email.trim() }).then(res => {
            if (res.data.status === 200) {
                if (res.data.data.users.length > 0)
                    this.setState({ users: res.data.data.users, dataFound: true })
                else
                    this.setState({
                        dataFound: true
                    })
            }
            else {
                this.setState({
                    open: true,
                    errMessage: 'Some error occured',
                    dataFound: true
                })
            }
        }, error => {
            console.error(error);
            this.setState({
                open: true,
                errMessage: 'Some error occured'
            })
        });
    }

    msgHide() {
        this.setState({
            open: false,
            message: '',
        })
    }

    componentWillMount() {
        this.getUsers();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpenDialog !== this.props.isOpenDialog) {
            this.setState({
                isOpenDialog: this.props.isOpenDialog,
                selEmails: this.props.selEmails.length > 0 ? this.props.selEmails.split(',') : [], sender_email: this.props.sender_email
            }, () => {
                this.getUsers();
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selEmails === "") {
            this.setState({
                selEmails: []
            })
        }
    }

    checkboxClick(event) {
        const { target: { value } } = event;

        var selEmails = this.state.selEmails;
        if (!(document.getElementById(value).checked)) {
            var selEmails = selEmails.filter(a => a.toLowerCase() !== value.toLowerCase());
        } else {
            selEmails.push(value);
        }
        this.setState({ selEmails });
    }

    searchChange(event) {
        const { target: { value } } = event;
        this.setState({
            search: value
        })
    }

    render() {
        // if (this.state.dataFound === false)
        //     return (<div style={{ marginTop: '20px', marginBottom: '20px', justifyContent: 'center', display: 'flex', fontWeight: 'bold' }}>
        //         Loading...
        //     </div>)
        return (
            <div id="at-contacts-list">
                <Dialog open={this.state.isOpenDialog}>
                    <div class="at-modalcontent modal-content">
                        <div class="at-popuptitle">
                            <div class="input-group" style={{ position: 'initial' }}>
                                <h4 style={{ marginTop: '10px' }}>Contacts</h4>
                                <a href="javascript:void(0);" className="at-closebtn close" onClick={() => {
                                    this.setState({ isOpenForward: false, emailAddess: '', password: '', emailError: '', isMailSent: false })
                                    this.props.onDialogClose(this.state.selEmails.join())
                                }} style={{ marginRight: '-17px' }}><i class="lnr lnr-cross" data-dismiss="modal"></i></a>
                                <input className="form-control" style={{ width: '200px', float: 'right', marginRight: '8px', marginLeft: '20px' }} onChange={(event) => this.searchChange(event)}
                                    type="text" value={this.state.search} placeholder="Search" />
                            </div>
                        </div>
                        <div class="modal-body" style={{ padding: 15, paddingTop: 0 }}>
                            {
                                (this.state.dataFound === false) ?
                                    <div style={{ marginTop: '20px', marginBottom: '20px', justifyContent: 'center', display: 'flex', fontWeight: 'bold' }}>
                                        Loading...
                                     </div>
                                    :
                                    <table className="filterTable" id="at-localities"
                                     style={{ marginTop: '10px', overflow:'auto', maxHeight:'300px', display:'block' }}>
                                        {
                                            this.state.users.length > 0 ?
                                                this.state.users.filter(u =>
                                                    u.emailid.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
                                                ).map(u => {
                                                    return (<tr>
                                                        <td style={{ width: '100%', border: "1px solid #d7d1d1", padding: '8px' }}>
                                                            <input type="checkbox" name="usersemail" id={u.emailid} value={u.emailid}
                                                                checked={this.state.selEmails.includes(u.emailid)} onClick={(e) => this.checkboxClick(e)} />
                                                            <span style={{ marginLeft: '10px' }}>{u.emailid}</span>
                                                        </td>
                                                        {/* <td style={{ width: '100%', border: "1px solid #d7d1d1", padding: '8px' }}>
                                                    <span style={{ marginLeft: '10px' }}>{u.name}</span>
                                                </td> */}
                                                    </tr>)
                                                })
                                                :
                                                <tr>
                                                    <td style={{ width: '100%', border: "1px solid #d7d1d1", padding: '20px' }}>
                                                        <span style={{ marginLeft: '10px' }}>No contacts found.</span>
                                                    </td>
                                                </tr>
                                        }
                                    </table>
                            }
                        </div>
                        <div class="form-group at-btnarea">
                            <button className="btn submit_btn" style={{ float: 'right', marginRight: '16px', marginTop: '-18px' }} onClick={() => this.props.onDialogClose(this.state.selEmails.join())}>OK</button>
                        </div>
                    </div>
                </Dialog>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={this.state.open}
                    onClose={this.msgHide}
                    autoHideDuration={5000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.errMessage}</span>}
                />
            </div>
        );
    }
}