import React, { Component } from 'react'
import GoogleLogin from 'react-google-login';
import { Icon, Button } from '@material-ui/core';
import { keys } from '../../constants/strings';

export default class Google extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            // userID:'',
            // name:'',
            // email:'',
            // picture:'',
            // tokenID:'',
            // accessToken:'',
            // type:3
        }
        this.responseGoogle = this.responseGoogle.bind(this);
    }
    responseGoogle(response) {
        this.setState({
            isLoggedIn: true,
            // userID:response.userID,
            // name:response.w3.ig,
            // email:response.w3.U3,
            // picture:response.w3.Paa,
            // tokenID:response.tokenId,
            // accessToken:response.accessToken,
            // type:3
        })
        this.props.onGoogleSuccessResponse(response, 3);
    };

    responseGoogleFailure(response) {
        //alert("Unable to login try another method.");
    };

    render() {
        let googleContent;
        // if(this.state.isLoggedIn) {
        //     // googleContent=(
        //     //     <div style={{
        //     //         width:'400px',
        //     //         margin:'auto',
        //     //         background:'#f4f4f4',
        //     //         padding:'20px'
        //     //     }}>
        //     //     <img src={this.state.picture} alt={this.state.name} />
        //     //     <h2>Welcome {this.state.name}</h2>
        //     //     Email : {this.state.email}
        //     //     </div>
        //     // );
        // }else{
        googleContent = (
            <GoogleLogin
                clientId={keys.google}
                buttonText="GOOGLE"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogleFailure}
                isMobile={false}
                status={true}
                // render={renderProps => (
                //     <img alt='' style={{cursor:'pointer'}} src="/public/images/Google.png" onClick={renderProps.onClick} disabled={renderProps.disabled}></img>
                //   )}
                render={renderProps => (
                    <button style={{
                        backgroundColor: '#dd4b39', color: 'white', width: '100%',
                        height: '40px', borderRadius: '5px', cursor: 'pointer'
                    }} onClick={renderProps.onClick}>{window.innerWidth > 760 ? 'Via Google' : ''}&nbsp;
                        <i style={{ color: 'white' }} class="fa fa-google">
                        </i>
                    </button>
                )}
            // autoLoad={true}      

            />
        );
        // }
        return (
            <div>
                {googleContent}
            </div>
        )
    }
}
