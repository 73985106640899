import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import validator from 'validator';
import API from '../../api';
// import styles from './style/sForgotPass';
import { getUrl, postUrl } from '../../constants/url';
import { Dialog } from '@material-ui/core';
import Loader from '../../components/Loader';
import DlgContacts from '../../components/dlgcontacts';

class Forward extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            emailError: '',
            emailFrom: this.props.user.EmailId,
            emailAddess: '',
            open: false,
            message: '',
            isOpenForward: this.props.isOpenForward,
            submit: 'Forward',
            selRow: this.props.selRow,
            isOpenDialog: false
        }

        this.emailChange = this.emailChange.bind(this);
        this.sendmail = this.sendmail.bind(this);
        this.msgHide = this.msgHide.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isOpenForward !== this.props.isOpenForward) {
            this.setState({ isOpenForward: this.props.isOpenForward, selRow: this.props.selRow })
        }
    }
    msgHide() {
        this.setState({
            open: false,
            message: '',
        })
    }

    emailChange(event) {
        const { target: { value } } = event;
        this.setState({
            emailAddess: value,
            emailError: '',
        })
    }

    sendmail(event) {
        if (this.state.emailAddess.trim() !== '') {
            var recEmails = this.state.emailAddess.trim().split(',');
            var rec = "";
            recEmails.map(email => {
                if (email.trim() != "") {
                    if (!validator.isEmail(email.trim())) {
                        this.setState({
                            emailAddess: 'Incorrect Emails'
                        });
                        return;
                    }
                    else {
                        rec += email.trim() + ",";
                    }
                }
            })
            this.setState({
                emailAddess: rec.slice(0, -1)
            }, () => {
                this.send();
            });
        }
        else {
            this.setState({
                emailError: 'Please enter emails'
            });
            return;
        }
    }

    send() {
        this.setState({ submit: <Loader /> })
        var data = {
            selRow: this.state.selRow,
            email_from: this.state.emailFrom,
            email_to: this.state.emailAddess,
            sender_user_id: this.props.user.User_Id,
            username: (this.props.user.Name !== null && this.props.user.Name !== '') ? this.props.user.Name : ''
        }
        API.post(postUrl.forward, data)
            .then(res => {
                if (res.data.status === 200) {
                    this.setState({
                        open: true,
                        message: 'Upload is forward to ' + this.state.emailAddess,
                        isOpenForward: false,
                        submit: 'Forward',
                        emailAddess: ''
                    });
                    this.props.onForwardClose();
                } else {
                    this.setState({
                        open: true,
                        message: res.data.error,
                        submit: 'Forward'
                    });
                }
            }).catch(function (err) {
                console.log(err)
            })
    }

    onOpenDialog() {
        if (this.state.emailFrom == '') {
            this.setState({
                open: true,
                message: 'Sender email not found.'
            })
            return;
        }
        this.setState({ isOpenDialog: true })
    }

    onDialogClose(selEmails) {
        const emailAddess = selEmails;

        this.setState({
            emailAddess, isOpenDialog: false
        });
    }

    render() {
        return (
            <div>
                <Dialog open={this.state.isOpenForward}>
                    <div class="at-modalcontent modal-content" >
                        <div class="at-popuptitle">
                            <div class="input-group d-flex flex-row" style={{ position: 'initial' }}>
                                <h4 style={{ marginTop: '5px' }}>Forward</h4>
                                <div style={{ float: 'right', marginLeft: '170px' }}>
                                    <img title='Contacts' src="/kanadnetworks/img/user.png" onClick={() => this.onOpenDialog()}
                                        style={{ width: '30px', cursor: 'pointer' }} />
                                </div>
                                <a href="javascript:void(0);" style={{ marginRight: '-17px' }} class="at-closebtn close" onClick={() => {
                                    this.setState({ isOpenForward: false, emailAddess: '', password: '', emailError: '', isMailSent: false })
                                    this.props.onForwardClose()
                                }}><i class="lnr lnr-cross" data-dismiss="modal"></i></a>
                            </div>
                        </div>
                        <div class="modal-body" style={{ padding: 15, paddingTop: 0 }}>
                            <div style={{ marginTop: '5px', width: '300px' }}>
                                <textarea style={{ height: '70px' }} className="form-control" id="email" name="email" value={this.state.emailAddess}
                                    onChange={e => this.setState({ emailAddess: e.target.value })} placeholder="Email (eg: a@example.com,b@example.com)" />
                                <p style={{ fontSize: '12px', color: 'red' }}>{this.state.emailError}</p>
                            </div>
                            <div class="form-group at-btnarea">
                                <button className="btn submit_btn" onClick={() => this.sendmail()}>{this.state.submit}</button>
                            </div>
                        </div>
                    </div>
                    <DlgContacts isOpenDialog={this.state.isOpenDialog} selEmails={this.state.emailAddess} onDialogClose={this.onDialogClose.bind(this)} sender_email={this.state.emailFrom} />
                </Dialog>
                <Snackbar
                    style={{ zIndex: 1300 }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={this.state.open}
                    onClose={this.msgHide}
                    autoHideDuration={5000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.message}</span>}
                />
            </div>
        );
    }
}

export default Forward;