import React, { Component } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Button, Icon } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import { keys } from '../../constants/strings'

export default class Facebook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      userID: '',
      name: '',
      email: '',
      picture: '',
      gender: '',
      AccessToken: '',
      Location: ''
    }
    this.responseFacebook = this.responseFacebook.bind(this);
  }
  responseFacebook(response) {

    this.setState(
      {
        isLoggedIn: true,
        // userID:response.userID,
        // name:response.name,
        // email:response.email,
        // picture:response.picture.data.url,
        // gender:response.gender,
        // AccessToken:response.accessToken
      })
    this.props.onFacebookSuccessResponse(response, 2)
  };

  render() {
    let fbContent;
    // if(this.state.isLoggedIn) {

    // } else{
    fbContent = (<FacebookLogin
      appId={keys.facebook}
      autoLoad={false}
      fields="name,email,picture"
      isMobile={false}
      status={true}
      // onClick={this.componentClicked}
      callback={this.responseFacebook}
      // size = "small"
      // cssClass="facebook"
      // cssClass="my-facebook-button-class"
      // textButton = "FACEBOOK"
      // icon="fa-facebook"
      // render={renderProps => (
      //   <img alt='' style={{cursor:'pointer'}} src="/kanadnetworks/images/facebook.png" onClick={renderProps.onClick} disabled={renderProps.disabled}></img>
      //         )}
      render={renderProps => (
        <button disabled={renderProps.disabled} style={{ backgroundColor: '#115293', color: 'white', width: '100%', height: '40px', borderRadius: '5px', cursor: 'pointer' }}
          onClick={renderProps.onClick} >{window.innerWidth > 760 ? 'Via Facebook' : ''}&nbsp;
          <FacebookIcon style={{ color: 'white' }}>
          </FacebookIcon>
        </button>
      )}
    />);
    // }
    return (
      <div>
        {fbContent}
      </div>
    )
  }
}
