import React, { Component } from 'react'
import API from '../../api';
import Slider from "react-slick";
import validator from 'validator';
import Loader from '../../components/Loader';
import Snackbar from '@material-ui/core/Snackbar';
import { postUrl } from '../../constants/url';
import { Progress } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';
import { cookies, dataUploadLimit } from '../../constants/strings';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios';
import DlgContacts from '../../components/dlgcontacts';
import Features from './features';
import Login from '../../components/Login';
import Register from '../../components/Login/Register';
import ForgotPass from '../../components/Login/ForgotPass';
var CancelToken = axios.CancelToken;
var cancelRequest;

export default class UploadFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submit: 'Transfer',
            size: 0,
            errors: {
                email_to: '',
                email_from: '',
                message: '',
                files: '',
                otp: ''
            },
            errMessage: '',
            open: false,
            data: {
                email_to: '',
                email_from: '',
                message: '',
                files: [],
                otp: ''
            },
            loaded: 0,
            showProgressBar: true,
            showLinearProgressBar: false,
            dataUploading: false,
            isOpenDialog: false,
            IsLoginOpen: false,
            IsRegisterDialogOpen: false,
            isOpenForgotPass: false,
            disabled: false
        }
        this.user = null;
        this.cookies = new Cookies();
        this.msgHide = this.msgHide.bind(this);
        this.noFileSel = false;
        this.inValidFileTYpes = "BAT,BIN,CMD,COM,CPL,EXE,GADGET,INFO,INS,INX,ISU,JOB,JSE,LNK,MSC,MSI,MSP,MST,PAF,PIF,PS1,REG,RGS,SCR,SCT,SHB,SHS,U3P,VB,VBE,VBS,VBSCRIPT,WS,WSF,WSH,KSH,OUT,RUN,SH,CSH,ACTION,APP,COMMAND,OSX,WORKFLOW,IPA,APK";
        this.onRegisterClose = this.onRegisterClose.bind(this);
        this.onLoginClose = this.onLoginClose.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
    }

    componentDidMount() {
        const user = this.cookies.get(cookies.user);
        if (user) {
            this.user = user;
            const data = this.state.data;
            data.email_from = user.EmailId;
            this.setState({
                data
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user !== null) {
            this.user = nextProps.user;
            const data = this.state.data;
            data.email_from = this.user.EmailId;
            this.setState({
                data
            });
        }
        if (nextProps.logout === true) {
            this.user = null;
            const data = this.state.data;
            data.email_from = '';
            this.setState({
                data
            });
        }
    }

    onUnload = e => {
        e.preventDefault();
        e.returnValue = '';
    }

    componentWillUnmount() {

    }

    msgHide() {
        this.setState({ errMessage: '', open: false })
    }

    emailChangeFrom(event) {
        const { target: { value } } = event;
        const data = this.state.data;
        const errors = this.state.errors;
        errors.email_from = validator.isEmail(value) ? '' : 'Incorrect Email';
        data.email_from = value;
        this.setState({
            data, errors
        });
    }

    emailChangeTo(event) {
        const { target: { value } } = event;
        const data = this.state.data;
        const errors = this.state.errors;
        errors.email_to = '';
        data.email_to = value;
        this.setState({
            data, errors
        });
    }

    messageChange(event) {
        const { target: { value } } = event;
        const data = this.state.data;
        const errors = this.state.errors;
        errors.message = '';
        data.message = value;
        this.setState({
            data, errors
        });
    }

    verify() {
        const errors = this.state.errors;
        let ver = true;

        if (this.state.data.email_from == '') {
            errors.email_from = 'Your email is mandatory';
            ver = false;
        }
        if (this.state.errors.email_from != '') {
            ver = false;
        }

        if (this.state.data.email_to == '') {
            errors.email_to = 'Email to is mandatory';
            ver = false;
        }
        if (this.state.errors.email_to != '') {
            ver = false;
        }

        if (this.state.data.email_to !== '') {
            var recEmails = this.state.data.email_to.split(',');
            var rec = "";
            recEmails.map(email => {
                if (email.trim() != "") {
                    if (!validator.isEmail(email.trim())) {
                        errors.email_to = 'Incorrect Emails';
                        ver = false;
                    }
                    else {
                        rec += email.trim() + ",";
                    }
                }
            })
            if (ver) {
                const data = this.state.data;
                data.email_to = rec.slice(0, -1);
                this.setState({
                    data
                });
            }
        }

        if (this.state.data.message == '') {
            errors.message = 'Message is mandatory';
            ver = false;
        }
        if (this.state.errors.message != '') {
            ver = false;
        }

        if (this.state.data.files.length == 0 && ver == true) {
            this.noFileSel = true;
            this.myInput.click();
        }

        this.setState({ errors });
        return ver;
    }

    validate() {
        if (this.verify()) {
            if (this.state.data.files.length > 0) {
                if (this.state.data.files.length > dataUploadLimit.file_count) {
                    this.setState({
                        open: true,
                        errMessage: dataUploadLimit.count_message
                    })
                    return;
                }
                var size = 0;
                for (var x = 0; x < this.state.data.files.length; x++) {
                    size += this.state.data.files[x].size;
                }
                size = size / 1024 / 1024 / 1024;
                if (size > dataUploadLimit.size) {
                    this.setState({
                        open: true,
                        errMessage: dataUploadLimit.size_message
                    })
                    return;
                }

                this.setState({ submit: <Loader /> })
                if (this.user) {
                    this.setState({
                        size: size
                    }, () => {
                        this.submit();
                    })
                }
                else {
                    const data = {
                        email: this.state.data.email_from
                    }
                    API.post(postUrl.sendEmailVerifyOTP, data).then(res => {
                        if (res.data.status === 200) {
                            this.setState({
                                open: true, errMessage: res.data.error,
                                submit: 'Verify', size: size
                            })
                        } else {
                            this.setState({ open: true, errMessage: res.data.error, submit: 'Transfer' })
                        }
                    })
                }
            }
        }
    }

    onClicked() {
        if (this.state.submit === "Transfer") {
            this.validate();
        }
        else if (this.state.submit === "Cancel") {
            this.cancel();
        }
        else if (this.state.submit === "Verify") {
            if (this.state.data.otp.trim() === '') {
                this.setState({
                    open: true,
                    errMessage: "Please enter OTP."
                })
                return;
            }
            const data = {
                email: this.state.data.email_from,
                password: this.state.data.otp
            }
            this.setState({ submit: <Loader /> })
            API.post(postUrl.verifyEmailOTP, data).then(res => {
                if (res.data.status === 200) {
                    this.submit();
                } else {
                    this.setState({ open: true, errMessage: res.data.error })
                }
            })
        }
    }

    cancel() {
        confirmAlert({
            title: 'Confirm to cancel upload',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => cancelRequest() //cancelTokenSource.cancel() 
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    submit() {
        window.addEventListener("beforeunload", this.onUnload);
        let self = this;
        this.props.onUploading(true);

        const data = new FormData()
        data.append('otp', this.state.data.otp);
        data.append('filesize', this.state.size);
        data.append('filecount', this.state.data.files.length);
        data.append('username', this.user ? this.user.Name : '');

        for (var x = 0; x < this.state.data.files.length; x++) {
            data.append('fullpath', this.state.data.files[x].webkitRelativePath);
            data.append('file', this.state.data.files[x]);
        }

        let folderPathDetails = {
            email_from: this.state.data.email_from.trim(),
            email_to: this.state.data.email_to.trim(),
            message: this.state.data.message
        }

        const data1 = this.state.data;
        data1.otp = '';
        this.setState({ submit: 'Cancel', data: data1, dataUploading: true, disabled: true });

        axios({
            method: 'post',
            url: postUrl.uploadFile(folderPathDetails),
            data: data,
            cancelToken: new CancelToken(function executor(c) {
                cancelRequest = c;
            }),
            onUploadProgress: ProgressEvent => {
                this.setState({
                    loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
                })
                if ((ProgressEvent.loaded / ProgressEvent.total * 100) === 100) {
                    this.setState({
                        showLinearProgressBar: true,
                        showProgressBar: false
                    })
                }
            }
        }).then(r => {
            if (r.data.status === 200) {
                let data = {
                    email_to: '',
                    email_from: this.user ? this.user.EmailId : '',
                    message: '',
                    files: [],
                    otp: ''
                }

                self.setState({ submit: 'Transfer', data, disabled: false });
                self.setState({
                    open: true,
                    errMessage: 'Data transfered successfully.',
                    loaded: 0,
                    showLinearProgressBar: false,
                    showProgressBar: true,
                    dataUploading: false
                })
            }
            else {
                self.setState({
                    submit: 'Transfer',
                    open: true,
                    errMessage: r.data.error,
                    loaded: 0,
                    showLinearProgressBar: false,
                    showProgressBar: true,
                    dataUploading: false,
                    disabled: false
                })
            }
            this.props.onUploading(false);
            window.removeEventListener("beforeunload", this.onUnload);
        }).catch(function (error) {
            if (!axios.isCancel(error)) {
                console.log(error)
                self.setState({
                    open: true,
                    errMessage: 'Some error occured'
                });
            }
            self.setState({
                loaded: 0,
                showLinearProgressBar: false,
                showProgressBar: true,
                submit: 'Transfer',
                dataUploading: false,
                disabled: false
            })
            this.props.onUploading(false);
            window.removeEventListener("beforeunload", this.onUnload);
        });
    }

    ValidateFiles(arrInputs) {
        var _invalidFileExtensions = this.inValidFileTYpes.split(",");
        var blnInValid = false;
        for (var i = 0; i < arrInputs.length; i++) {
            var oInput = arrInputs[i];
            //if (oInput.type == "file") {
            var sFileName = oInput.name;
            if (sFileName.length > 0) {
                for (var j = 0; j < _invalidFileExtensions.length; j++) {
                    var sCurExtension = _invalidFileExtensions[j];
                    if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                        blnInValid = true;
                        break;
                    }
                }
            }
            //}
        }
        return blnInValid;
    }

    imputFileChange(event) {
        const { target: { value } } = event;
        this.setState({ error: '' });

        const data = this.state.data;
        const errors = this.state.errors;

        var invalidFiles = this.ValidateFiles(event.target.files);
        if (invalidFiles) {
            if (event.target.files.length > 1) {
                errors.files = 'Invalid/executable files!';
            }
            else {
                errors.files = 'Invalid/executable file!';
            }
            data.files = [];
            this.noFileSel = false;
        }
        else {
            errors.files = '';
            data.files = event.target.files;
        }

        this.setState({
            data, errors
        }, () => {
            if (this.noFileSel === true) {
                this.onClicked();
                this.noFileSel = false;
            }
        });
    }

    imputFolderChange(event) {
        const { target: { value } } = event;
        this.setState({ error: '' });

        const data = this.state.data;
        const errors = this.state.errors;

        var invalidFiles = this.ValidateFiles(event.target.files);
        if (invalidFiles) {
            errors.files = 'Invalid/executable files!';
            data.files = [];
        }
        else {
            errors.files = '';
            data.files = event.target.files;
        }

        this.setState({
            data, errors
        }, () => {
            console.log('')
        });
    }

    otpChange(event) {
        const { target: { value } } = event;
        const data = this.state.data;
        const errors = this.state.errors;
        errors.otp = '';
        data.otp = value;
        this.setState({
            data, errors
        });
    }

    onOpenDialog() {
        if (!this.user) {
            this.setState({ IsLoginOpen: true })
        }
        else {
            this.setState({ isOpenDialog: true })
        }
    }

    onDialogClose(selEmails) {
        const data = this.state.data;
        const errors = this.state.errors;
        errors.email_to = '';
        data.email_to = selEmails;
        this.setState({
            data, errors, isOpenDialog: false
        });
    }

    onRegisterClose() {
        this.setState({ IsRegisterDialogOpen: false })
    }
    onLoginClose() {
        this.setState({ IsLoginOpen: false })
    }
    onSuccess() {
        const user = this.cookies.get(cookies.user);
        if (user) {
            this.user = user;
            const data = this.state.data;
            data.email_from = user.EmailId;
            this.setState({
                data
            });
            this.props.onLogin(user);
        }
    }

    onForgotPassOpen() {
        this.setState({ isOpenForgotPass: true, IsLoginOpen: false })
    }
    onForgotPasswordClose() {
        this.setState({ isOpenForgotPass: false })
    }

    onLoginNow() {
        this.setState({ IsRegisterDialogOpen: false, IsLoginOpen: true, isOpenForgotPass: false })
    }
    onSignupNow() {
        this.setState({ IsRegisterDialogOpen: true, IsLoginOpen: false })
    }

    render() {
        return (
            <section class="home_banner_area" id="at-home-banner">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide"><img src="/kanadnetworks/img/slider/KanadNetworks-Main1.jpg" alt="" />
                            <div class="slider_text_inner">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="slider_text">
                                                <div className="row contact_form">
                                                    <div className="col-md-12">
                                                        <div class="input-group d-flex flex-row">
                                                            <input id="myInput" name="myInput" multiple type="file" ref={(ref) => this.myInput = ref} style={{ display: 'none' }} onChange={e => this.imputFileChange(e)} />
                                                            <input id="myInputFolder" name="myInputFolder" directory="" webkitdirectory="" type="file" ref={(ref) => this.myInputFolder = ref} style={{ display: 'none' }} onChange={e => this.imputFolderChange(e)} />
                                                            <div class="plus radius" style={{ marginTop: '4px' }} onClick={(e) => this.state.disabled === false && this.myInput.click()}></div>
                                                            <div id="at-browse-buttons" style={{ marginBottom: '20px' }}>
                                                                <p style={{ marginLeft: '10px', fontSize: '20px', marginBottom: '3px', cursor: 'pointer' }} onClick={(e) => this.state.disabled === false && this.myInput.click()}>Add your files</p>
                                                                <a style={{ marginLeft: '10px', color: '#a7cb00', fontSize: '15px', textDecoration: 'underline', cursor: 'pointer' }}
                                                                    onClick={(e) => this.state.disabled === false && this.myInputFolder.click()}>Or select a folder
                                                                </a>
                                                            </div>
                                                            <p style={{ marginLeft: '20px', paddingTop: '8px', color: (this.state.errors.files ? "red" : '#a7cb00') }}>
                                                                {this.state.data.files.length > 0 ? (this.state.data.files.length > 1 ? this.state.data.files.length + " files selected" : this.state.data.files.length + " file selected") : this.state.errors.files}
                                                            </p>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="email" className="form-control" id="email_from" name="email_from" disabled={this.state.disabled} value={this.state.data.email_from} onChange={e => this.emailChangeFrom(e)} placeholder="Your email" />
                                                            <p style={{ color: 'red' }}>{this.state.errors.email_from}</p>
                                                        </div>
                                                        <div className="form-group">
                                                            <div class="input-group">
                                                                <input type="email" className="form-control" id="email_to" name="email_to" disabled={this.state.disabled}
                                                                    value={this.state.data.email_to} onChange={e => this.emailChangeTo(e)}
                                                                    placeholder="Email to (eg: a@example.com,b@example.com)" />
                                                                <div style={{ border: '1px solid #d7cece', cursor: 'pointer' }}>
                                                                    <img title="Contacts" src="/kanadnetworks/img/user.png" onClick={() => this.onOpenDialog()}
                                                                        style={{ width: '26px', height: 'auto', cursor: 'pointer', marginTop: '5px' }} />
                                                                </div>
                                                            </div>
                                                            <p style={{ color: 'red' }}>{this.state.errors.email_to}</p>
                                                        </div>
                                                        <div className="form-group">
                                                            <textarea className="form-control" id="message" name="message" disabled={this.state.disabled} value={this.state.data.message} onChange={e => this.messageChange(e)} placeholder="Message" />
                                                            <p style={{ color: 'red' }}>{this.state.errors.message}</p>
                                                        </div>
                                                        {this.state.loaded > 0 && this.state.showProgressBar && !this.state.showLinearProgressBar ?
                                                            <div class="form-group" style={{ marginBottom: '10px' }}>
                                                                <ToastContainer />
                                                                <Progress striped max="100" color="success" value={this.state.loaded} >{Math.round(this.state.loaded, 2)}%</Progress>
                                                            </div> : <div> </div>
                                                        }

                                                        {this.state.loaded === 100 && !this.state.showProgressBar && this.state.showLinearProgressBar ?
                                                            <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                <h4 style={{ color: ' #a7cb00', fontSize: '12px' }}>{this.state.data.files.length > 1 ? "Validating files" : "Validating file"}</h4>
                                                                <LinearProgress style={{ backgroundColor: '#a7cb00' }} />
                                                            </div> : <div> </div>
                                                        }
                                                        <div className="form-group">
                                                            {
                                                                this.state.submit === "Verify" ?
                                                                    <input className="form-control" style={{ maxWidth: '55%', border: '1px solid #a7cb00' }} type="text" id="otp" name="otp" value={this.state.data.otp} onChange={e => this.otpChange(e)} placeholder="Enter otp here" />
                                                                    : <div></div>
                                                            }
                                                            <button style={{ float: 'right' }} className="btn submit_btn" onClick={() => { this.onClicked() }}>{this.state.submit}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DlgContacts isOpenDialog={this.state.isOpenDialog} selEmails={this.state.data.email_to} onDialogClose={this.onDialogClose.bind(this)} sender_email={this.state.data.email_from} />
                        <ForgotPass isOpenForgotPass={this.state.isOpenForgotPass} onLoginNow={this.onLoginNow.bind(this)} onForgotPasswordClose={this.onForgotPasswordClose.bind(this)} />
                        <Login IsLoginOpen={this.state.IsLoginOpen} onLoginClose={this.onLoginClose.bind(this)} onSuccess={this.onSuccess.bind(this)}
                            onSignupNow={this.onSignupNow.bind(this)} onForgotPassOpen={this.onForgotPassOpen.bind(this)} />
                        <Register IsRegisterDialogOpen={this.state.IsRegisterDialogOpen} onRegisterClose={this.onRegisterClose} onSuccess={this.onSuccess.bind(this)} updateProfile={this.state.registerDialogForProile} onLoginNow={this.onLoginNow.bind(this)} />
                    </div>
                    <section class="success_area">
                        <div class="row m0 right_dir" id="at-digitalservices">
                            <div class="col-lg-6 p0">
                                <h4 style={{ marginLeft: '20px', justifyContent: 'center', display: 'flex' }}>Share, store, manage & collaborate</h4>
                                <h4 style={{ marginLeft: '20px', justifyContent: 'center', display: 'flex' }} >With elegance & ease!</h4>
                                <h3 style={{ marginLeft: '20px', justifyContent: 'center', display: 'flex' }} >On Safe & Secure Servers located in Indian Territory</h3>
                                <p style={{ marginLeft: '20px' }}>Terakut® offers an elegant and secure environment to let you store, share and manage your files and data - at an incredible speed and ease of use.</p>
                                <p style={{ marginLeft: '20px' }}>Experience global standards of service, offered on Indian servers, built and managed by an Indian organization!</p>
                            </div>
                            <div class="col-lg-6 p0" style={{ marginTop: '50px' }}>
                                {/* <h4 style={{ marginLeft: '100px' }} >Key Features</h4> */}
                                <Features></Features>
                            </div>
                        </div>
                    </section>

                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        open={this.state.open}
                        onClose={this.msgHide}
                        autoHideDuration={5000}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{this.state.errMessage}</span>}
                    />
                </div>
            </section >
        )
    }
}