import React from 'react';
import ReactDOM from 'react-dom';
import { browserHistory } from 'react-router';
import Routes from './Routes';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <React.StrictMode>
        <Routes history={browserHistory} />
    </React.StrictMode>,
    document.getElementById('root')
);
serviceWorker.unregister();