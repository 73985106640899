import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import validator from 'validator';
import API from '../../api';
// import styles from './style/sForgotPass';
import { getUrl, postUrl } from '../../constants/url';
import { Dialog } from '@material-ui/core';
import Loader from '../Loader';

class ForgotPass extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			emailError: '',
			emailAddess: '',
			open: false,
			message: '',
			isOpenForgotPass: this.props.isOpenForgotPass,
			submit: 'Send email'
		}
		
		this.signIn = this.signIn.bind(this);
		this.emailChange = this.emailChange.bind(this);
		this.sendmail = this.sendmail.bind(this);
		this.msgHide = this.msgHide.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isOpenForgotPass !== this.props.isOpenForgotPass) {
			this.setState({ isOpenForgotPass: this.props.isOpenForgotPass })
		}
	}
	msgHide() {
		this.setState({
			open: false,
			message: '',
		})
	}

	signIn() {
		this.setState({ isOpenForgotPass: false, emailAddess: '', password: '', emailError: '' });
		this.props.onLoginNow();
		// browserHistory.goBack()
	}

	emailChange(event) {
		const { target: { value } } = event;
		this.setState({
			emailAddess: value,
			emailError: '',
		})
	}

	sendmail(event) {
		if (!validator.isEmail(this.state.emailAddess)) {
			this.setState({ emailError: 'Invalid Email' })
			return
		}
		this.setState({ submit: <Loader /> })
		API.post(postUrl.forgotPass, { email: this.state.emailAddess })
			.then(res => {
				console.log('res', res)
				if (res.data.status === 200) {
					this.setState({
						open: true,
						message: 'An Email is sent to ' + this.state.emailAddess,
						isOpenForgotPass: false,
						submit: 'Send email'
					});
				} else {
					this.setState({
						open: true,
						message: res.data.error,
						submit: 'Send email'
					});
				}
			}).catch(function (err) {
				console.log(err)
			})
	}

	render() {
		return (
			<div>
				<Dialog open={this.state.isOpenForgotPass}>
					<div class="at-modalcontent modal-content" >
						<div class="at-popuptitle">
							<h4>Forgot Password</h4>
							<a href="javascript:void(0);" class="at-closebtn close" onClick={() => {
								this.setState({ isOpenForgotPass: false, emailAddess: '', password: '', emailError: '', isMailSent: false })
								this.props.onForgotPasswordClose()
							}}><i class="lnr lnr-cross" data-dismiss="modal"></i></a>
						</div>
						<div class="modal-body" style={{ padding: 15, paddingTop: 0 }}>
							<div style={{ marginTop: '5px', width:'300px' }}>
								<input type="email" className="form-control" id="outlined-email" name="outlined-email"
									onChange={(e) => this.setState({ emailAddess: e.target.value })}
									placeholder="Email*" />
								<p style={{ fontSize: '12px' }}>{this.state.emailError}</p>
							</div>

							<div class="form-group at-btnarea">
								<button className="btn submit_btn" onClick={() => this.sendmail()}>{this.state.submit}</button>
							</div>

							<span class="at-optionsbar" style={{ marginTop: 5, marginBottom: 10 }}><em></em></span>
							<div className="at-loginfooterinfo">
								<a onClick={this.signIn} style={{ cursor: 'pointer', width:'100%' }}>Go Back to Sign In</a>
							</div>
						</div>
					</div>
				</Dialog>
				<Snackbar
					style={{ zIndex: 1300 }}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					open={this.state.open}
					onClose={this.msgHide}
					autoHideDuration={5000}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}
					message={<span id="message-id">{this.state.message}</span>}
				/>
			</div>
		);
	}
}

export default ForgotPass;